const Unicycle = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#c2cde7"
      d="M33 63c-12.131 0-22-9.869-22-22s9.869-22 22-22 22 9.869 22 22-9.869 22-22 22zm0-40c-9.926 0-18 8.076-18 18s8.076 18 18 18 18-8.076 18-18-8.074-18-18-18z"
    />
    <path
      fill="#8d6c9f"
      d="M33 64c-12.682 0-23-10.318-23-23s10.318-23 23-23 23 10.318 23 23-10.318 23-23 23zm0-44c-11.58 0-21 9.42-21 21s9.42 21 21 21 21-9.42 21-21-9.42-21-21-21zm0 40c-10.477 0-19-8.523-19-19s8.523-19 19-19 19 8.523 19 19-8.523 19-19 19zm0-36c-9.374 0-17 7.626-17 17s7.626 17 17 17 17-7.626 17-17-7.626-17-17-17z"
    />
    <path fill="#f283a5" d="M31 16h4v22h-4z" />
    <path
      fill="#8d6c9f"
      d="M35 39h-4a1 1 0 0 1-1-1V16a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1zm-3-2h2V17h-2v20z"
    />
    <path fill="#ced9ed" d="M31 10h4v6h-4z" />
    <path
      fill="#8d6c9f"
      d="M35 17h-4a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-3-2h2v-4h-2v4z"
    />
    <path
      fill="#f9dd8f"
      d="M32.944 9C27.038 9 22.468 6.225 21 5.213c.599-1.052 2.107-3.267 4.156-3.267.496 0 1.012.133 1.532.393C28.351 3.171 30.7 4 33 4c3.201 0 6.513-1.279 9.429-2.741A2.349 2.349 0 0 1 43.504 1C45.243 1 47.057 2.89 48 4.126 45.838 5.364 38.97 9 32.944 9z"
    />
    <path fill="#f283a5" d="M20 34h6v4h-6z" />
    <path
      fill="#8d6c9f"
      d="M26 39h-6a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-5-2h4v-2h-4v2z"
    />
    <path fill="#f283a5" d="M39 46h6v4h-6z" />
    <path
      fill="#8d6c9f"
      d="M45 51h-6a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-5-2h4v-2h-4v2z"
    />
    <path
      fill="#8d6c9f"
      d="M38.999 47a.998.998 0 0 1-.523-.148l-13-8a1 1 0 1 1 1.047-1.703l13 8A1 1 0 0 1 38.999 47z"
    />
    <circle cx={33} cy={41} r={3} fill="#acb7d0" />
    <path
      fill="#8d6c9f"
      d="M33 45c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
    />
    <g fill="#8d6c9f">
      <path d="M7.315 38.173a.91.91 0 0 1-.147-.011l-1.98-.292a1 1 0 0 1-.843-1.135 1.006 1.006 0 0 1 1.135-.843l1.979.292a1.001 1.001 0 0 1-.144 1.989zM8.445 33.518c-.108 0-.218-.018-.327-.055l-1.891-.653a1.001 1.001 0 0 1 .653-1.891l1.891.653a1.001 1.001 0 0 1-.326 1.946zM10.416 29.148a1 1 0 0 1-.495-.131l-1.737-.991a1 1 0 0 1 .991-1.737l1.737.991a1 1 0 0 1-.496 1.868zM13.161 25.215a.996.996 0 0 1-.646-.237l-1.526-1.292a.999.999 0 1 1 1.292-1.526l1.526 1.292a.999.999 0 0 1-.646 1.763zM16.581 21.849a.994.994 0 0 1-.775-.368l-1.265-1.55a1 1 0 0 1 1.55-1.264l1.265 1.55a1 1 0 0 1-.775 1.632z" />
    </g>
    <path
      fill="#f9e3ae"
      d="M45 6s-6 2-8 3c-1.264-1.928 3.801-6.308 6-7 1.669-.525 5 2 5 2l-3 2z"
    />
    <path
      fill="#8d6c9f"
      d="M32.944 11c-6.132 0-10.825-3.126-12.557-4.471a.998.998 0 0 1-.279-1.242c.799-1.577 2.518-4.224 5.049-4.224.668 0 1.349.192 2.021.57.93.524 3.356 1.741 5.822 1.741 2.489 0 5.412-.966 8.938-2.955A3.172 3.172 0 0 1 43.504 0c2.382 0 4.516 2.766 5.323 3.955a1 1 0 0 1-.286 1.402C45.907 7.054 39.115 11 32.944 11zM22.302 5.454C24.092 6.699 28.061 9 32.944 9 38.043 9 43.9 5.848 46.557 4.238 45.458 2.854 44.312 2 43.504 2c-.202 0-.387.052-.583.163-3.887 2.191-7.04 3.212-9.921 3.212-2.834 0-5.479-1.252-6.803-1.999-.369-.208-.719-.313-1.04-.313-1.194 0-2.256 1.404-2.855 2.391z"
    />
  </svg>
)
export default Unicycle
