const Bicycle = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#c2cde7"
      d="M12 30a11 11 0 1 0 0 22 11 11 0 1 0 0-22zm40 0a11 11 0 1 0 0 22 11 11 0 1 0 0-22z"
    />
    <path
      fill="#e2e9f7"
      d="M12 34a7 7 0 1 0 0 14 7 7 0 1 0 0-14zm40 0a7 7 0 1 0 0 14 7 7 0 1 0 0-14z"
    />
    <path
      fill="#8d6c9f"
      d="M30.75 42H12.5a1 1 0 0 1-.818-1.577l12-17c.188-.265.493-.423.818-.423h20.1a1 1 0 0 1 .966.739l.351 1.3c.088.328.004.68-.225.932l-14.2 15.7a1 1 0 0 1-.742.329zm-16.32-2h15.876l13.537-14.967-.009-.033H25.019L14.43 40z"
    />
    <path
      fill="#8d6c9f"
      d="M30.75 42c-.399 0-.776-.241-.932-.636l-9-23a1 1 0 0 1 1.863-.729l9 23A1 1 0 0 1 30.75 42zM52 42a.999.999 0 0 1-.948-.684l-10-30A1 1 0 0 1 42 10h6.5c1.963 0 3.5 1.537 3.5 3.5S50.463 17 48.5 17h-.25a1 1 0 1 1 0-2h.25c.841 0 1.5-.659 1.5-1.5s-.659-1.5-1.5-1.5h-5.112l9.561 28.684A1 1 0 0 1 52 42zM12 53C5.383 53 0 47.617 0 41s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-22C6.486 31 2 35.486 2 41s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10z"
    />
    <path
      fill="#8d6c9f"
      d="M12 49c-4.411 0-8-3.589-8-8s3.589-8 8-8a1 1 0 1 1 0 2c-3.309 0-6 2.691-6 6s2.691 6 6 6a5.966 5.966 0 0 0 4.723-2.3 1 1 0 0 1 1.574 1.234A7.954 7.954 0 0 1 12 49zm40 4c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-22c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10z"
    />
    <path
      fill="#8d6c9f"
      d="M44.586 44.996a.999.999 0 0 1-.376-1.927l1.854-.751a1 1 0 1 1 .752 1.854l-1.854.751a.998.998 0 0 1-.376.073zm3.836 4.153a1.001 1.001 0 0 1-.893-1.447l.894-1.789a1 1 0 0 1 1.789.895l-.894 1.789a1 1 0 0 1-.896.552zm5.624.578c-.442 0-.847-.296-.966-.743l-.514-1.933a1.001 1.001 0 0 1 1.934-.514l.514 1.933a1.001 1.001 0 0 1-.968 1.257zm4.604-3.282a.998.998 0 0 1-.555-.169l-1.662-1.111a.999.999 0 1 1 1.112-1.662l1.662 1.111a.999.999 0 0 1-.557 1.831zm-.706-5.245a1 1 0 0 1-.132-1.991l1.982-.267a1 1 0 0 1 .266 1.982l-1.982.267c-.045.006-.09.009-.134.009z"
    />
    <path fill="#f9dd8f" d="M28 13H16a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4z" />
    <path
      fill="#8d6c9f"
      d="M28 18H16c-1.654 0-3-1.346-3-3s1.346-3 3-3h12c1.654 0 3 1.346 3 3s-1.346 3-3 3zm-12-4a1 1 0 0 0 0 2h12a1 1 0 0 0 0-2H16z"
    />
  </svg>
)
export default Bicycle
