const OatMilk = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path fill="#85cbf8" d="M49 11H32V8a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v3z" />
    <path fill="#ace3ff" d="M32 11h17l-4 9-9.583 1L27 20z" />
    <path fill="#7bbeeb" d="M45 59h8V20l-4-9-4 9z" />
    <path fill="#85cbf8" d="M27 20h18v39H27z" />
    <path
      fill="#ace3ff"
      d="M50 38c-1-2-1-5-5-5 0 0-6.372.384-9.279-2.523S31.36 24.547 27 26v33h26V40s-2 0-3-2z"
    />
    <path fill="#8d6c9f" d="M41 21h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2z" />
    <path
      fill="#8d6c9f"
      d="m53.916 19.602-.002-.008L50 10.787V8c0-1.654-1.346-3-3-3H34c-1.654 0-3 1.346-3 3v2.741l-4.874 8.774c-.011.02-.016.042-.025.063-.012.026-.021.051-.031.078A.964.964 0 0 0 26 20v39a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V20a.995.995 0 0 0-.084-.398zM49 13.463 51.461 19h-4.923L49 13.463zM34 7h13c.552 0 1 .449 1 1v2H33V8c0-.551.448-1 1-1zm10 51H28V21h6a1 1 0 1 0 0-2h-5.301l3.889-7H47.46l-3.375 7.594-.002.008A1.012 1.012 0 0 0 44 20v38zm2 0V21h6v37h-6z"
    />
    <path
      fill="#f9dd8f"
      d="M9 28s7.382 1.725 8.908 3.251c1.525 1.525 1.225 4.432-.044 5.701-1.269 1.269-4.087 1.481-5.613-.044S9 28 9 28z"
    />
    <path
      fill="#efc88e"
      d="M8 41s6.315 4 8.472 4S21 42.795 21 41s-2.371-4-4.528-4S8 41 8 41z"
    />
    <path
      fill="#f9dd8f"
      d="M22 27s4 6.315 4 8.472C26 37.629 23.795 40 22 40s-4-2.371-4-4.528C18 33.315 22 27 22 27z"
    />
    <path
      fill="#f9dd8f"
      d="M27 32s4 6.315 4 8.472C31 42.629 28.795 45 27 45s-4-2.371-4-4.528C23 38.315 27 32 27 32z"
    />
    <path
      fill="#efc88e"
      d="M13 46s6.315 4 8.472 4S26 47.795 26 46s-2.371-4-4.528-4S13 46 13 46z"
    />
    <path
      fill="#f9dd8f"
      d="M32 37s4 6.315 4 8.472c0 2.157-2.205 4-4 4s-4-1.843-4-4S32 37 32 37z"
    />
    <path
      fill="#efc88e"
      d="M18 51s6.315 4 8.472 4 4-2.205 4-4-1.843-4-4-4S18 51 18 51z"
    />
    <path
      fill="#8d6c9f"
      d="m40.707 58.293-7.911-7.911c2.063-.434 4.204-2.46 4.204-4.91 0-2.329-3.461-7.911-4.155-9.007-.367-.578-1.322-.578-1.689 0l-.298.478c-1.19-2.547-2.917-5.325-3.008-5.472-.365-.586-1.332-.586-1.697 0-.07.112-.174.28-.293.476-1.19-2.548-2.918-5.329-3.01-5.476-.365-.586-1.332-.586-1.697 0a61.337 61.337 0 0 0-2.403 4.227c-.046-.05-.086-.106-.134-.154-1.644-1.644-8.107-3.219-9.387-3.518a1 1 0 0 0-1.202 1.202c.299 1.279 1.874 7.743 3.517 9.386.048.048.104.089.154.135a61.337 61.337 0 0 0-4.227 2.403.998.998 0 0 0 0 1.697c.147.091 2.928 1.82 5.476 3.01-.196.119-.364.223-.476.293a.998.998 0 0 0 0 1.697c.147.091 2.925 1.818 5.472 3.008l-.478.298a1.002 1.002 0 0 0 0 1.69c1.096.694 6.678 4.155 9.007 4.155 2.451 0 4.476-2.141 4.911-4.204l7.911 7.911a.997.997 0 0 0 1.413 0 .999.999 0 0 0 0-1.414zM35 45.472c0 1.545-1.69 3-3 3s-3-1.455-3-3c0-.971 1.28-3.556 2.594-5.852l.004-.006c.134-.234.268-.469.402-.695 1.442 2.441 3 5.481 3 6.553zm-5.404-6.397c-1.012 1.793-2.159 4.058-2.493 5.614l-2.502-2.502c-.28-.366-.601-.931-.601-1.617 0-1.073 1.555-4.148 2.998-6.627.804 1.382 1.973 3.502 2.598 5.132zm-7.598-10.132c.807 1.388 1.984 3.523 2.606 5.155-.996 1.787-2.128 4.041-2.485 5.607l-2.518-2.518c-.28-.366-.601-.931-.601-1.617 0-1.073 1.555-4.148 2.998-6.627zm-9.04 7.257c-.759-.759-1.842-4.043-2.582-6.824 2.781.739 6.065 1.823 6.824 2.582.24.24.41.555.535.91C17.3 33.921 17 34.889 17 35.57c0 .151.008.304.024.454A4.287 4.287 0 0 0 16.57 36c-.685 0-1.662.304-2.723.744-.342-.132-.649-.304-.889-.544zm-3.015 4.798C12.422 39.555 15.497 38 16.57 38c.686 0 1.251.321 1.617.601l2.518 2.518c-1.566.357-3.82 1.489-5.608 2.485-1.631-.622-3.765-1.799-5.154-2.606zM26.472 54c-1.071 0-4.11-1.558-6.554-3 2.442-1.442 5.482-3 6.554-3 1.545 0 3 1.69 3 3s-1.455 3-3 3zm-6.397-5.404c-1.63-.625-3.75-1.794-5.132-2.598C17.422 44.555 20.497 43 21.57 43c.686 0 1.251.321 1.617.601l2.502 2.502c-1.556.334-3.821 1.481-5.614 2.493z"
    />
  </svg>
)
export default OatMilk
