const Moniteur = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path fill="#acb7d0" d="M45 59H19l3-4h20l3 4z" />
    <path fill="#a0aecb" d="M27 49h10v6H27z" />
    <rect width={62} height={40} x={1} y={9} fill="#c2cde7" rx={2} ry={2} />
    <path fill="#cfd9ed" d="M1 43h62v3H1z" />
    <path fill="#c5e4fa" d="M1 13h62v30H1z" />
    <path
      fill="#e3f2ff"
      d="M45.93 43 62 26.93V13h-2.14l-30 30h16.07zm-26.79 0h6.43l30-30h-6.43l-30 30zm-4.28 0 30-30H39.5l-30 30h5.36z"
    />
    <path
      fill="#fff8f8"
      d="m18.5 18 1.24 2.26L22 21.5l-2.26 1.24L18.5 25l-1.24-2.26L15 21.5l2.26-1.24L18.5 18zm30.92-.84.85 1.57 1.57.85-1.57.86-.85 1.56-.86-1.56-1.56-.86 1.56-.85.86-1.57zM32.65 34.7l.58 1.07 1.07.58-1.07.59-.58 1.06-.59-1.06-1.06-.59 1.06-.58.59-1.07z"
    />
    <path
      fill="#8d6c9f"
      d="M61 8H3a3 3 0 0 0-3 3v36a3 3 0 0 0 3 3h23v4h-2.34a5 5 0 0 0-3.54 1.46l-2.83 2.83A1 1 0 0 0 18 60h28a1 1 0 0 0 .71-1.71l-2.83-2.83A5 5 0 0 0 40.34 54H38v-4h23a3 3 0 0 0 3-3V11a3 3 0 0 0-3-3ZM40.34 56a3 3 0 0 1 2.12.88L43.59 58H20.41l1.12-1.12a3 3 0 0 1 2.13-.88h16.68ZM36 54h-8v-4h8Zm25-6H3a1 1 0 0 1-1-1v-3h37.08a1 1 0 0 0 0-2H2V11a1 1 0 0 1 1-1h58a1 1 0 0 1 1 1v31h-3a1 1 0 0 0 0 2h3v3a1 1 0 0 1-1 1Z"
    />
    <path
      fill="#fc6d74"
      d="M44 41a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Z"
    />
    <path
      fill="#34c695"
      d="M49 41a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Z"
    />
    <path
      fill="#5eb0dd"
      d="M54 41a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Z"
    />
    <path
      fill="#8d6c9f"
      d="M60 13a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 1-1zm-9-1H5a1 1 0 0 0 0 2h46a1 1 0 0 0 0-2z"
    />
  </svg>
)
export default Moniteur
