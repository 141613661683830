const Salad = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#b0edcc"
      d="m30.576 19.624 3.231 1.838L35 23h8l-.279-2.267 6.84-4.149-.519-.855c-2.864-4.722-9.014-6.228-13.736-3.363a9.98 9.98 0 0 0-4.73 7.258"
    />
    <path fill="#f283a5" d="M27 9a10 10 0 1 0 0 20 10 10 0 1 0 0-20Z" />
    <path
      fill="#ced9ed"
      d="M58.5 28h-53a2.5 2.5 0 1 1 0-5h53a2.5 2.5 0 1 1 0 5zM57 28c-.67 8.73-5.07 16.26-12 20.45V53H19v-4.55C12.07 44.26 7.67 36.73 7 28h50z"
    />
    <path
      fill="#b5c4e0"
      d="M56.372 32a17.475 17.475 0 0 0 1.313-4H6.315a17.475 17.475 0 0 0 1.313 4h48.744z"
    />
    <path
      fill="#5dbc9d"
      d="M22 23c0-3.875-6-12-16-12 0 7 4 12 4 12m32-1c0-3.875 6-11 16-11 0 7-4 12-4 12"
    />
    <g fill="#8d6c9f">
      <path d="M14 40a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1z" />
      <path d="M58.5 22h-2.626C57.125 19.907 59 15.948 59 11a1 1 0 0 0-1-1c-3.983 0-7.433 1.104-10.174 2.726a10.979 10.979 0 0 0-12.875-1.305A10.955 10.955 0 0 0 27 8a11.004 11.004 0 0 0-9.551 5.553C14.541 11.488 10.629 10 6 10a1 1 0 0 0-1 1c0 4.948 1.875 8.907 3.126 11H5.5C3.57 22 2 23.57 2 25.5S3.57 29 5.5 29h.531a25.97 25.97 0 0 0 11.494 20.6c.297.2.475.516.475.846V52c0 1.103.897 2 2 2h24c1.103 0 2-.897 2-2v-1.553c0-.331.178-.647.476-.848a25.97 25.97 0 0 0 11.493-20.6h.531c1.93 0 3.5-1.57 3.5-3.5S60.43 22 58.5 22zm-5.009 0h-4.502c.448-2.368 2.093-3.831 3.127-4.749.232-.206.435-.387.592-.544a.999.999 0 1 0-1.414-1.414c-.134.134-.308.286-.505.462-1.208 1.072-3.343 3.001-3.807 6.245H43c0-2.885 4.75-9.51 13.97-9.975-.297 5.008-2.671 8.813-3.479 9.975zm-7.385-8.117C42.871 16.337 41 19.5 41 22h-3.424c.275-.971.424-1.975.424-3 0-2.21-.661-4.266-1.788-5.992a8.984 8.984 0 0 1 9.894.875zM27 10c4.963 0 9 4.037 9 9a8.96 8.96 0 0 1-.523 3H23c0-2.186-1.434-4.88-3.954-7.164C20.593 11.887 23.638 10 27 10zM7.03 12.025C16.251 12.489 21 19.115 21 22h-3.106c-.464-3.244-2.599-5.174-3.807-6.245-.197-.176-.371-.328-.505-.462a.999.999 0 1 0-1.414 1.414c.157.157.359.338.592.544 1.034.918 2.679 2.381 3.127 4.749h-5.379c-.808-1.156-3.182-4.947-3.478-9.975zm38.33 35.914A3.027 3.027 0 0 0 44 50.447V52H20v-1.554a3.03 3.03 0 0 0-1.359-2.507A23.97 23.97 0 0 1 8.031 29h47.938A23.971 23.971 0 0 1 45.36 47.939zM58.5 27h-53c-.827 0-1.5-.673-1.5-1.5S4.673 24 5.5 24h53c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5z" />
    </g>
  </svg>
)
export default Salad
