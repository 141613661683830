const ChauffeEau = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#ced9ed"
      d="M46 56H18a5 5 0 0 1-5-5V11a5 5 0 0 1 5-5h28a5 5 0 0 1 5 5v40a5 5 0 0 1-5 5z"
    />
    <path
      fill="#b5c4e0"
      d="M51 50v1c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5v-1h38z"
    />
    <path fill="#6e727a" d="M20 13h24v29H20z" />
    <path
      fill="#8b8e98"
      d="M43.84 42H24.3L20 37.69V18.18zM44 17.42v6.51L33.07 13h6.51zm0 12.207v7.82l-23.97-23.96h7.83z"
    />
    <path fill="#acb7d0" d="M20 56h5v5h-5zm19 0h5v5h-5z" />
    <path
      fill="#f9dd8f"
      d="M38.816 23.122a13.221 13.221 0 0 0-.562-.971.387.387 0 0 0-.309-.17.377.377 0 0 0-.394.359c-.015 1.147-.989 2.869-1.7 3.516l-.005-.001c.595-3.912-1.171-6.614-5.245-8.791a.393.393 0 0 0-.295-.035.37.37 0 0 0-.264.458c.428 1.499 1.958 5.457-.03 7.513-.302-2.046-1.587-3.637-2.505-3.981a.379.379 0 0 0-.512.322C26.766 25.203 24 26.131 24 30.248c0 .093.002.186.005.279.16 4.279 3.87 7.623 8.285 7.467 4.337-.151 7.71-3.732 7.71-7.935v-.2c.003-2.128-.194-4.837-1.184-6.737z"
    />
    <path
      fill="#f9e3ae"
      d="M40 30.347v.2c0 .87-.14 1.7-.41 2.49l-8.82-8.82c.72-2.13-.38-5.01-.73-6.24-.06-.2.06-.4.27-.46.09-.03.2-.02.29.03 4.07 2.18 5.84 4.88 5.24 8.8h.01c.65-.59 1.52-2.07 1.67-3.2l1.84 1.84c.53 1.7.64 3.71.64 5.36zm-2.43 5.383a7.868 7.868 0 0 1-5.28 2.26c-4.41.16-8.12-3.18-8.28-7.46-.01-.1-.01-.19-.01-.28 0-2.8 1.28-4.13 2.17-5.9l11.4 11.38z"
    />
    <path
      fill="#a0a3aa"
      d="m24 16 .707 1.293L26 18l-1.293.707L24 20l-.707-1.293L22 18l1.293-.707z"
    />
    <path
      fill="#faefde"
      d="m33.5 30 .884 1.616L36 32.5l-1.616.884L33.5 35l-.884-1.616L31 32.5l1.616-.884zm-1-5 .53.97.97.53-.97.53-.53.97-.53-.97-.97-.53.97-.53z"
    />
    <path
      fill="#8d6c9f"
      d="M46 5H18c-3.309 0-6 2.691-6 6v40c0 3.309 2.691 6 6 6h1v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-4h12v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-4h1c3.309 0 6-2.691 6-6V11c0-3.309-2.691-6-6-6zM18 7h28c2.206 0 4 1.794 4 4v30h-5V13a1 1 0 0 0-1-1H20a1 1 0 0 0-1 1v28h-5V11c0-2.206 1.794-4 4-4zm3 34V14h22v27H21zm3 19h-3v-3h3v3zm19 0h-3v-3h3v3zm3-5H18c-2.206 0-4-1.794-4-4v-8h36v8c0 2.206-1.794 4-4 4z"
    />
    <path
      fill="#8d6c9f"
      d="M39.702 22.66a14.6 14.6 0 0 0-.616-1.063 1.386 1.386 0 0 0-2.497.445c-.718-2.342-2.533-4.265-5.5-5.85a1.397 1.397 0 0 0-1.05-.126 1.372 1.372 0 0 0-.959 1.697c.047.162.105.351.171.562.303.979.762 2.475.757 3.813-.628-1.031-1.436-1.786-2.176-2.064a1.387 1.387 0 0 0-.361-.073c-.781-.062-1.419.519-1.474 1.282-.096 1.608-.68 2.603-1.355 3.753C23.872 26.345 23 27.83 23 30.249l.006.317C23.184 35.28 27.184 39 31.996 39c.109 0 .22-.002.33-.006C37.108 38.827 41 34.818 41 30.06v-.199c.004-3.164-.42-5.519-1.298-7.201zM39 30.06c0 3.694-3.025 6.806-6.744 6.936-3.897.146-7.111-2.783-7.252-6.499L25 30.249c0-1.875.633-2.953 1.366-4.201.569-.97 1.2-2.044 1.486-3.518a5.33 5.33 0 0 1 1.171 2.616c.006.042.03.075.041.114a.95.95 0 0 0 .069.191.936.936 0 0 0 .113.164c.026.033.04.073.071.103.017.016.039.021.056.036.049.042.104.071.16.103.06.034.117.067.182.087.052.016.106.021.161.028.075.01.146.019.221.012.021-.002.04.006.061.003.041-.006.073-.03.112-.04.067-.018.131-.038.193-.07s.115-.073.168-.116c.032-.026.07-.038.099-.068.022-.023.034-.05.056-.073.015-.019.029-.036.043-.056 1.712-1.917 1.182-4.86.631-6.83 3.605 2.39 4.344 5.346 2.583 9.881a1.001 1.001 0 0 0 1.865.723c.412-1.061.696-2.062.863-3.013.485-.534 1.025-1.367 1.383-2.254.564 1.363.848 3.305.845 5.786v.203H39zM22 48a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zm-5 0a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zm10 0a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zm4 3a1 1 0 0 0 2 0v-2a1 1 0 0 0-2 0v2zm5 0a1 1 0 0 0 2 0v-2a1 1 0 0 0-2 0v2zm5 0a1 1 0 0 0 2 0v-2a1 1 0 0 0-2 0v2zm5 0a1 1 0 0 0 2 0v-2a1 1 0 0 0-2 0v2z"
    />
  </svg>
)
export default ChauffeEau
