const AnimauxDomestiques = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#ced9ed"
      d="M60 40s1-1.333 1-4c0-4.667-2.964-8-2.964-8l-6.607 5.333S48.476 32 43.5 32s-7.929 1.333-7.929 1.333L28.964 28s-2.643 2.667-2.643 8c0 2.667.679 4 .679 4s-1.938 3.436-2 12c0 0 4.633 8 18.5 8S62 52 62 52c-.062-8.564-2-12-2-12z"
    />
    <path
      fill="#dbb2ba"
      d="M36.273 22.665a6.54 6.54 0 0 1-.998-4.118c.14-1.46.037-2.992-.402-4.577-1.636-5.916-7.143-10.147-13.279-9.964-7.176.215-12.927 6.099-12.927 13.327 0 .432.022.858.065 1.278.144 1.43-.24 2.837-1.004 4.053a11.257 11.257 0 0 0-1.69 6.924C6.509 35.535 11.714 40 17.679 40h8.643c5.965 0 11.169-4.465 11.642-10.411a11.265 11.265 0 0 0-1.691-6.924z"
    />
    <path
      fill="#cda1a7"
      d="M16 34a5.97 5.97 0 0 1-2.86 5.1c-3.87-1.59-6.76-5.15-7.1-9.51 0-.03 0-.05-.01-.08A5.926 5.926 0 0 1 10 28c3.31 0 6 2.69 6 6zm21.97-4.49c-.01.03-.01.05-.01.08-.34 4.36-3.23 7.92-7.1 9.51h-.01A5.973 5.973 0 0 1 28 34c0-3.31 2.69-6 6-6 1.53 0 2.92.57 3.97 1.51z"
    />
    <path
      fill="none"
      stroke="#8d6c9f"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M48 36.49c1.1.25 1.7.49 1.7.49l.3.135m-11.015-.692A21.644 21.644 0 0 1 43.33 36c.22 0 .436.003.646.009"
    />
    <path
      fill="#c2cde7"
      d="M34.94 58.78C27.65 56.54 25 52 25 52c.02-3.43.45-6.04.96-7.93 2.05.37 3.49 2.28 3.25 4.39-.09.72-.15 1.47-.18 2.25.67.74 1.95 1.94 4.03 3.01 1.86.95 2.65 3.16 1.88 5.06zm17.12 0C59.35 56.54 62 52 62 52c-.02-3.43-.45-6.04-.96-7.93a3.997 3.997 0 0 0-3.25 4.39c.09.72.15 1.47.18 2.25-.67.74-1.95 1.94-4.03 3.01a3.998 3.998 0 0 0-1.88 5.06z"
    />
    <path
      fill="#8d6c9f"
      d="M47 49.25c0 .69-2.333 3.75-3.5 3.75S40 49.94 40 49.25 41.567 48 43.5 48s3.5.56 3.5 1.25zM48 43a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm-9 0a2 2 0 1 0 0 4 2 2 0 1 0 0-4z"
    />
    <path
      fill="none"
      stroke="#8d6c9f"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M48 55c-2.667 0-4.667-3-4.667-3S41.667 55 39 55"
    />
    <path
      fill="#c4939c"
      d="M8.67 17.33c0 .43.02.86.06 1.28.05.44.04.87-.02 1.31l-.04.08C5.72 20 2 17.61 2 14.67 2 11.72 7.33 4 11.33 4c1.52 0 2.83.84 3.51 2.09-3.71 2.37-6.17 6.52-6.17 11.24zM42 14.67c0 2.94-3.72 5.33-6.67 5.33l-.03-.06a6.45 6.45 0 0 1-.03-1.39c.15-1.46.04-2.99-.4-4.58-.9-3.28-3-6.05-5.74-7.83.67-1.28 2-2.14 3.54-2.14 4 0 9.33 7.72 9.33 10.67z"
    />
    <path
      fill="#8d6c9f"
      d="M61.13 40.054c.358-.698.87-2.044.87-4.054 0-4.99-3.085-8.517-3.217-8.664a1 1 0 0 0-1.375-.114l-6.157 4.97C50.116 31.788 47.401 31 43.5 31c-1.919 0-3.545.194-4.831.433.135-.575.243-1.161.291-1.765a12.237 12.237 0 0 0-1.84-7.535 5.754 5.754 0 0 1-.572-1.246C39.712 20.338 43 17.774 43 14.67 43 11.374 37.374 3 32.67 3a4.97 4.97 0 0 0-3.848 1.824c-2.184-1.218-4.675-1.887-7.258-1.818-2.346.07-4.534.698-6.453 1.743A4.983 4.983 0 0 0 11.33 3C6.626 3 1 11.374 1 14.67c0 3.106 3.292 5.672 6.458 6.218a5.896 5.896 0 0 1-.578 1.244 12.243 12.243 0 0 0-1.84 7.536C5.545 36.022 11.097 41 17.679 41h8.292c-.822 1.843-1.93 5.357-1.971 10.993a.996.996 0 0 0 .135.508C24.336 52.849 29.221 61 43.5 61s19.164-8.151 19.365-8.499a.996.996 0 0 0 .135-.508c-.051-6.931-1.312-10.636-1.87-11.939zM32.67 5C36.085 5 41 12.214 41 14.67c0 2.012-2.445 3.828-4.733 4.241.004-.09-.005-.179.003-.269a13.568 13.568 0 0 0-.434-4.938 14.666 14.666 0 0 0-5.323-7.788A2.99 2.99 0 0 1 32.67 5zM3 14.67C3 12.214 7.915 5 11.33 5c.787 0 1.521.318 2.071.85a14.265 14.265 0 0 0-5.734 11.484c0 .453.023.916.069 1.377.007.067-.001.134.002.201C5.449 18.501 3 16.684 3 14.67zm4.034 14.84a10.253 10.253 0 0 1 1.539-6.313c.916-1.457 1.314-3.077 1.153-4.685A12.272 12.272 0 0 1 21.972 5c5.458 0 10.443 3.835 11.938 9.237.382 1.379.506 2.797.371 4.214-.159 1.657.237 3.298 1.146 4.746a10.246 10.246 0 0 1 1.539 6.313C36.543 34.831 31.867 39 26.321 39h-8.643c-5.545 0-10.221-4.169-10.644-9.49zM43.5 59c-11.961 0-16.653-6.036-17.497-7.286.096-6.671 1.779-10.01 2.292-10.878 4.284-.662 7.901-3.43 9.62-7.199C39.194 33.324 41.096 33 43.5 33c4.667 0 7.491 1.233 7.518 1.244a.995.995 0 0 0 1.039-.133l5.806-4.686C58.658 30.592 60 33.003 60 36c0 2.25-.799 3.398-.8 3.4a.998.998 0 0 0-.071 1.091c.018.031 1.769 3.228 1.868 11.223C60.148 52.971 55.454 59 43.5 59z"
    />
    <path
      fill="#f7a1c8"
      d="M26 28.25c0 .69-1.791 3.75-4 3.75s-4-3.06-4-3.75S19.791 27 22 27s4 .56 4 1.25z"
    />
    <path
      fill="none"
      stroke="#8d6c9f"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M22 39.667V32m4-3.75c0 .69-1.791 3.75-4 3.75s-4-3.06-4-3.75S19.791 27 22 27s4 .56 4 1.25z"
    />
    <path
      fill="#8d6c9f"
      d="M27 19a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm-10 0a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm0-7a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5-1a1 1 0 0 0 1-1V8a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1zm5 1a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1z"
    />
  </svg>
)
export default AnimauxDomestiques
