const Taxi = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path fill="#f9e3ae" d="M40 9H24l1-6h14l1 6z" />
    <rect width={7} height={6} x={3} y={23} fill="#c2cde7" rx={2} ry={2} />
    <rect width={7} height={6} x={54} y={23} fill="#c2cde7" rx={2} ry={2} />
    <path
      fill="#f6d397"
      d="m55 27-4 2H12.5L8 27l-3 7v12a5 5 0 0 0 5 5h44a5 5 0 0 0 5-5V34Z"
    />
    <path fill="#f9e3ae" d="M51 29H12.5L8 27l-3 7v11h54V34l-4-7-4 2z" />
    <path fill="#8b8e98" d="M7 51h8v8H7zm42 0h8v8h-8z" />
    <path
      fill="#bbdef9"
      d="M13.35 15a7.42 7.42 0 0 1 7.16-5.5h23a7.42 7.42 0 0 1 7.14 5.5l2.13 8a9.46 9.46 0 0 0 1.58 3.26l.63.84-1.84.9a5.32 5.32 0 0 1-2.33.54H13.28a5.32 5.32 0 0 1-2.35-.54L9 27l.6-.8a9.47 9.47 0 0 0 1.61-3.2Z"
    />
    <path
      fill="#def0ff"
      d="M54.37 26.24A9.46 9.46 0 0 1 52.79 23l-2.13-8a7.34 7.34 0 0 0-.79-1.83l-15.4 15.36h16.35a5.32 5.32 0 0 0 2.09-.44l1.62-1.62zM42.27 9.47 23.21 28.53h6.56l17.8-17.8a7.37 7.37 0 0 0-4.08-1.26zm-27.8 19.06h4L37.53 9.47h-4L14.47 28.53z"
    />
    <path fill="#c2cde7" d="M17 41H5v-6l12 2v4zm30 0h12v-6l-12 2v4z" />
    <path
      fill="#8d6c9f"
      d="M22 42a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm5 0a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm5.17 2H16.92a1 1 0 1 0 0 2h30.25a1 1 0 0 0 0-2zm3.75 2h4.25a1 1 0 0 0 0-2h-4.25a1 1 0 1 0 0 2zm-37.75-2H8.92a1 1 0 0 0 0 2h4.25a1 1 0 0 0 0-2zm16.75-28h4.25a1 1 0 0 0 0-2h-4.25a1 1 0 1 0 0 2z"
    />
    <path
      fill="#8d6c9f"
      d="M63 58h-5.56a4 4 0 0 0 .56-2v-5a5 5 0 0 0 2-4V36a13.08 13.08 0 0 0-1.47-6H59a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3h-5.5l-2-7.32A9 9 0 0 0 42.85 8h-1.13L40.4 4.05A3 3 0 0 0 37.56 2H26.44a3 3 0 0 0-2.84 2.05L22.28 8h-1.13a9 9 0 0 0-8.7 6.68L10.5 22H5a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h.47A13.08 13.08 0 0 0 4 36v11a5 5 0 0 0 2 4v5a4 4 0 0 0 .56 2H1a1 1 0 0 0 0 2h62a1 1 0 0 0 0-2Zm-5-11a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3v-5h10a2 2 0 0 0 2-2v-.46a4 4 0 0 0-3.34-3.95l-8.33-1.38h-.18a11.07 11.07 0 0 1 2-4.8l.88-1.18 2.13 1.05a7 7 0 0 0 3.1.73h35.52a7 7 0 0 0 3.08-.71l2.09-1 .85 1.14a11.08 11.08 0 0 1 2 4.8h-.18l-8.28 1.36A4 4 0 0 0 46 39.54V40a2 2 0 0 0 2 2h10Zm-42 5h32v4a4 4 0 0 0 .56 2H15.44a4 4 0 0 0 .56-2Zm-1.67-14.43a2 2 0 0 1 1.67 2V40H6v-3.82ZM39.14 28a4 4 0 0 1 7.72 0ZM58 40H48v-.46a2 2 0 0 1 1.67-2L58 36.18Zm2-15v2a1 1 0 0 1-1 1h-1.75l-2.52-3.36a7.07 7.07 0 0 1-.42-.64H59a1 1 0 0 1 1 1ZM25.49 4.68a1 1 0 0 1 .95-.68h11.12a1 1 0 0 1 .95.68L39.61 8H24.39ZM14.38 15.2a7 7 0 0 1 6.77-5.2h21.7a7 7 0 0 1 6.76 5.2l2 7.56a9 9 0 0 0 1.5 3.08l.6.79-1.71.86a5 5 0 0 1-2.2.51h-.87a6 6 0 0 0-11.82 0H14.32a5 5 0 0 1-2.22-.52l-1.8-.89.56-.75a8.94 8.94 0 0 0 1.5-3.08ZM4 27v-2a1 1 0 0 1 1-1h4.69a7.06 7.06 0 0 1-.42.64L6.75 28H5a1 1 0 0 1-1-1Zm6 31a2 2 0 0 1-2-2v-4h6v4a2 2 0 0 1-2 2Zm42 0a2 2 0 0 1-2-2v-4h6v4a2 2 0 0 1-2 2Z"
    />
  </svg>
)
export default Taxi
