const Tennis = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#f6d397"
      d="M16.66 16.66A8 8 0 1 1 19 11.22a8 8 0 0 1-2.34 5.44Z"
    />
    <path
      fill="#acb7d0"
      d="M57.26 6.74C49.81-.72 36.8.2 28.21 8.79c-5.5 5.5-8.35 12.31-7.42 18.77a22.71 22.71 0 0 1-.32 8.15v.14a16 16 0 0 1-3.21 6.64l-2 2.43 3.83 3.83 2.42-2a16 16 0 0 1 6.66-3.21l.26-.06a22.52 22.52 0 0 1 8-.33c6.46.92 13.28-1.9 18.77-7.39 8.6-8.56 9.52-21.57 2.06-29.02Z"
    />
    <path
      fill="#ced8ed"
      d="M57.26 6.74C49.81-.72 36.8.2 28.21 8.79c-5.5 5.5-8.35 12.31-7.42 18.77a22.71 22.71 0 0 1-.32 8.15v.14a16 16 0 0 1-3.21 6.64l-2 2.43 1.66 1.66S44 20 57.26 6.74Z"
    />
    <path
      fill="#c5e4fa"
      d="M52.51 32.59c-6.57 6.57-15.67 8.13-22.29 1.51s-5.07-15.72 1.51-22.29S48.28 4.56 54 10.3s5.08 15.7-1.49 22.29Z"
    />
    <path
      fill="#f6d397"
      d="M2.23 61.77a4 4 0 0 1 0-5.66l11.9-11.9a2 2 0 0 1 2.83 0L19.79 47a2 2 0 0 1 0 2.83L7.89 61.77a4 4 0 0 1-5.66 0Z"
    />
    <path
      fill="#f9e3ae"
      d="M15.79 46.87a2 2 0 0 0 0-2.83l-.4-.4a2 2 0 0 0-1.26.57l-11.9 11.9a4 4 0 0 0-1 3.82 4 4 0 0 0 2.69-1.16Z"
    />
    <path
      fill="#8d6c9f"
      d="M5.3 18a8.9 8.9 0 0 0 5.7 2h.25a9 9 0 0 0-.49-18 9 9 0 0 0-6.12 15.36q.36.32.66.64zm-1-5v-.05a7 7 0 0 1-.21-1v-.11a7 7 0 0 1 6.12 6.11h-.15a7 7 0 0 1-1-.19h-.1a6.9 6.9 0 0 1-.89-.34l-.17-.08a6.93 6.93 0 0 1-.9-.58l-.16-.12a6.45 6.45 0 0 1-1.5-1.5l-.1-.14a6.93 6.93 0 0 1-.52-.87l-.08-.17a6.85 6.85 0 0 1-.35-.96zm7.62-8.94a7 7 0 0 1 1 .2H13a6.89 6.89 0 0 1 1 .37l.17.08a6.91 6.91 0 0 1 .87.52l.14.11a6.47 6.47 0 0 1 1.51 1.5l.12.16a6.92 6.92 0 0 1 .52.87l.08.17a6.88 6.88 0 0 1 .36.93v.06a7 7 0 0 1 .2 1v.11a7 7 0 0 1-6.12-6.12zm-5.86 2a7 7 0 0 1 3.77-1.95 8.9 8.9 0 0 0 2.59 5.48 8.91 8.91 0 0 0 5.48 2.59 7 7 0 0 1-5.72 5.71 8.91 8.91 0 0 0-2.59-5.48 9 9 0 0 0-5.48-2.58 7 7 0 0 1 1.94-3.78zM58 6A18.78 18.78 0 0 0 43.09.75 24.65 24.65 0 0 0 27.5 8.09c-5.85 5.85-8.65 13-7.7 19.62a21.61 21.61 0 0 1-.3 7.79v.15a15.08 15.08 0 0 1-3 6.22l-.67.82a3 3 0 0 0-2.36.83L1.52 55.41a5 5 0 1 0 7.07 7.07l11.9-11.9a3 3 0 0 0 .88-2.12v-.26l.78-.64a15.09 15.09 0 0 1 6.24-3l.26-.06a21.43 21.43 0 0 1 7.66-.31c6.64.95 13.8-1.85 19.62-7.67a24.65 24.65 0 0 0 7.34-15.59A18.78 18.78 0 0 0 58 6zM19.08 49.16l-11.9 11.9a3.07 3.07 0 0 1-4.24 0 3 3 0 0 1 0-4.24l11.9-11.9a1 1 0 0 1 1.41 0l2.83 2.83a1 1 0 0 1 0 1.41zm42.17-28.39a22.65 22.65 0 0 1-6.75 14.32c-5.37 5.37-11.91 8-17.93 7.11a23.39 23.39 0 0 0-8.37.34l-.26.06a17.09 17.09 0 0 0-7.08 3.4l-.39.31-2.8-2.8.32-.4a17.06 17.06 0 0 0 3.41-7.05v-.15a23.64 23.64 0 0 0 .33-8.51c-.86-6 1.74-12.52 7.13-17.92a22.65 22.65 0 0 1 14.37-6.73 16.84 16.84 0 0 1 13.32 4.7 16.81 16.81 0 0 1 4.7 13.32z"
    />
    <path
      fill="#8d6c9f"
      d="M58.24 15.56a14.06 14.06 0 0 0-3.52-6l-.27-.24v-.06l-.1-.07c-4.72-4.36-11.84-4.93-18-1.92a1 1 0 0 0-.18.09A19.85 19.85 0 0 0 31 11.1c-7.39 7.39-8.1 16.7-1.86 23.32a.94.94 0 0 0 .05.08l.25.28A14.62 14.62 0 0 0 40 39.36c.48 0 1 0 1.46-.07a19.69 19.69 0 0 0 11.74-6c4.94-4.94 6.69-11.73 5.11-17.45a1 1 0 0 0-.07-.28zm-2.5 10.36-3.45-3.45L56.75 18a15.38 15.38 0 0 1-1.01 7.92zm-3.26 5.23L48 26.71l2.83-2.83 4 4a17.9 17.9 0 0 1-2.35 3.27zM47.8 35l-4-4 2.87-2.87 4.44 4.44A20.62 20.62 0 0 1 47.8 35zm-12.3 1.44a13.58 13.58 0 0 1-4.16-2.7l1.91-1.91 1.28 1.28a1 1 0 0 0 1.41-1.41l-1.28-1.28 2.87-2.87 3.4 3.4-5.35 5.35a.94.94 0 0 0-.07.17zm-8-8.45.93-.93 3.37 3.37-1.8 1.9A13 13 0 0 1 27.54 28zm4.21-14.77 3.87 3.87L32.75 20l-3.45-3.47a20.59 20.59 0 0 1 2.45-3.29zm4.72-3.77 3.39 3.39L37 15.7l-3.86-3.86a17.93 17.93 0 0 1 3.33-2.37zm15.76.59-1.86 1.86-1-1a1 1 0 0 0-1.41 1.41l1 1-2.9 2.9-3.37-3.37 5-5a12.26 12.26 0 0 1 4.58 2.22zm-1.86 4.69 3.41 3.4-2.9 2.9-3.4-3.4zm-5.73 2.9-2.83 2.83-3.37-3.37 2.83-2.83zm-7.6.89 3.37 3.37-2.87 2.87-3.37-3.37zm-.91 7.65L33.25 29l-3.37-3.37 2.87-2.87zm2.83 0 2.87-2.87 1.43 1.43a1 1 0 0 0 1.41-1.41l-1.43-1.43 2.83-2.83 3.4 3.4-7.11 7.11zm17.29-10.45-1 1-3.41-3.4 1.9-1.9a12.09 12.09 0 0 1 2.51 4.3zM45.28 7.45l-4 4-2.89-2.88a15.85 15.85 0 0 1 5.86-1.17c.35 0 .69.02 1.03.05zm-17 10.92 3 3L27 25.68a14 14 0 0 1 1.32-7.31zm13 18.92a12.29 12.29 0 0 1-3.63-.19l4.7-4.7 3.59 3.6a14.87 14.87 0 0 1-4.65 1.3zm-14.89-2.43A1 1 0 0 0 25 36.27L27.7 39a1 1 0 0 0 1.41-1.41z"
    />
  </svg>
)
export default Tennis
