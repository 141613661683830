const ItalianPizza = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#f9e3ae"
      d="M58.837 33.717c.441 1.094-.04 2.341-1.116 2.824l-7.911 3.547L8.066 58.803c-1.82.816-3.685-1.049-2.869-2.869L23.912 14.19l3.547-7.911c.482-1.076 1.73-1.557 2.824-1.116a51.68 51.68 0 0 1 28.554 28.554z"
    />
    <path
      fill="#efc88e"
      d="M58.837 33.717A51.68 51.68 0 0 0 30.283 5.163c-1.094-.441-2.341.04-2.824 1.116L26.23 9.021a2.21 2.21 0 0 1 1.053.142c12.878 5.189 23.171 15.436 28.434 28.277l2.004-.899c1.076-.483 1.557-1.73 1.116-2.824z"
    />
    <path
      fill="#f283a5"
      d="M49.81 40.088 8.066 58.803c-1.82.816-3.685-1.049-2.869-2.869L23.912 14.19c12.2 4.048 21.85 13.698 25.898 25.898z"
    />
    <path
      fill="#fff8ee"
      d="M15.803 55.149A7.364 7.364 0 0 0 16 53.485c0-3.621-3.332-5.895-6.812-6.452l-2.187 4.878-1.835 4.507c-.087.195-.137.39-.156.582A2.2 2.2 0 0 0 7 58.99c.192-.019.387-.068.582-.156l8.221-3.685zM34 36a8 8 0 0 0-8 8c0 2.229.913 4.243 2.384 5.694l13.596-6.095C41.769 39.368 38.283 36 34 36zm-12 0a8 8 0 0 0 0-16c-.239 0-.475.015-.709.036l-5.707 12.729A7.98 7.98 0 0 0 22 36z"
    />
    <g fill="#8d6c9f">
      <path d="M59.764 33.343C54.452 20.158 43.843 9.549 30.657 4.235c-1.604-.643-3.408.072-4.11 1.634l-3.177 7.087L4.285 55.524a3.148 3.148 0 0 0 .652 3.54 3.152 3.152 0 0 0 3.539.651l7.921-3.551.003-.002L50.219 41l7.911-3.547a3.179 3.179 0 0 0 1.634-4.11zM22 21c3.86 0 7 3.141 7 7s-3.14 7-7 7a6.936 6.936 0 0 1-5.247-2.396l5.202-11.602L22 21zM6.351 57.65c-.134-.135-.547-.624-.241-1.308l3.637-8.112c2.895.748 5.038 3.316 5.234 6.376L7.657 57.89c-.683.306-1.172-.106-1.306-.24zM27 44c0-3.859 3.14-7 7-7 3.46 0 6.415 2.566 6.914 5.98L28.64 48.483A6.994 6.994 0 0 1 27 44zm30.312-8.371-6.921 3.103a41.974 41.974 0 0 0-.786-2.027 1 1 0 0 0-1.843.778c.288.682.55 1.376.798 2.07l-5.768 2.586C41.924 38.026 38.26 35 34 35c-4.962 0-9 4.037-9 9a8.99 8.99 0 0 0 1.758 5.327l-9.857 4.419a9.05 9.05 0 0 0-6.331-7.352l5.303-11.827A8.913 8.913 0 0 0 22 37c4.962 0 9-4.037 9-9 0-4.682-3.594-8.536-8.167-8.958l1.618-3.609c7.131 2.547 13.355 7.053 18.042 13.073a.999.999 0 1 0 1.578-1.228A41.734 41.734 0 0 0 25.26 13.627l3.111-6.939A1.172 1.172 0 0 1 29.447 6c.153 0 .31.029.462.091 12.684 5.11 22.889 15.316 28 28a1.187 1.187 0 0 1-.597 1.538z" />
      <path d="M46.398 30.581c-.295-.465-.914-.604-1.38-.309s-.604.914-.309 1.381c.514.81 1.006 1.651 1.463 2.502a.997.997 0 0 0 1.354.408 1 1 0 0 0 .408-1.354 42.119 42.119 0 0 0-1.536-2.628zM31.683 8.96a1 1 0 0 0-1.339.454l-.885 1.794a1 1 0 0 0 1.794.885l.885-1.794a1.001 1.001 0 0 0-.455-1.339zm4.383 2.423a1 1 0 0 0-1.376.327l-1.049 1.703a1 1 0 1 0 1.703 1.048l1.049-1.703a1 1 0 0 0-.327-1.375zm2.739 3.017-1.204 1.597a.999.999 0 1 0 1.596 1.203l1.204-1.597a.999.999 0 1 0-1.596-1.203zm5.259 2.995a1.001 1.001 0 0 0-1.413.065l-1.347 1.479a1 1 0 0 0 1.479 1.348l1.347-1.479a1 1 0 0 0-.066-1.413zm1.324 6.561c.24 0 .481-.086.673-.261l1.479-1.347a1 1 0 1 0-1.347-1.478l-1.479 1.347a1 1 0 0 0 .674 1.739zm5.409.839a1.002 1.002 0 0 0-1.401-.197l-1.598 1.204a1 1 0 0 0 1.204 1.597l1.598-1.204a1 1 0 0 0 .197-1.4zm2.82 4.14a1 1 0 0 0-1.376-.327l-1.703 1.049a1 1 0 0 0 1.049 1.704l1.703-1.049c.47-.291.617-.908.327-1.377zm1.084 3.928-1.793.885a1 1 0 0 0 .884 1.793l1.793-.885a1 1 0 0 0-.884-1.793z" />
    </g>
  </svg>
)
export default ItalianPizza
