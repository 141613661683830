const NaturalFood = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#72caaf"
      d="M60 19.86a1.64 1.64 0 0 0-1.63-1.54h-.76c-6.19 0-23.31.77-32.16 9.7A22.16 22.16 0 0 0 23.76 30c-6 7.77-4.32 16.43.86 22a18.31 18.31 0 0 0 12.61 6 17.88 17.88 0 0 0 11.92-4.07 22.81 22.81 0 0 0 1.94-1.73 26.42 26.42 0 0 0 5.66-9.08c3.52-9.04 3.44-19.44 3.25-23.26Z"
    />
    <path
      fill="#97e0bb"
      d="M59.63 18.91a1.67 1.67 0 0 0-1.25-.59h-.76c-5.07 0-17.5.51-26.69 5.63v.2a2.53 2.53 0 0 0 3.19 1.59 75.16 75.16 0 0 1 22.61-3.8 2.87 2.87 0 0 0 2.9-3.03Z"
    />
    <path
      fill="#5dbc9d"
      d="M53.89 40.89A3 3 0 0 0 51 42.18a24.8 24.8 0 0 1-5 5.6 22.26 22.26 0 0 1-2.09 1.48 16.25 16.25 0 0 1-11.08 2.6 16.16 16.16 0 0 1-8.08-3.73 5.24 5.24 0 0 0-3.45-1.23 18.07 18.07 0 0 0 3.31 5.1 18.85 18.85 0 0 0 6.46 4.54c.54.14 1.08.26 1.63.35a21.2 21.2 0 0 0 14.87-3.23 27.74 27.74 0 0 0 2.59-1.83 28.93 28.93 0 0 0 5-5.23c.48-.89.91-1.81 1.3-2.75a3 3 0 0 0-2.57-2.96Z"
    />
    <path
      fill="#97e0bb"
      d="M3 32.6C4.3 50.93 27 53 29.58 30.53c.09-.77.18-1.54.2-2.33.39-11-7.64-20.29-11-23.69a2 2 0 0 0-2.87 0C12.37 8.34 3.39 19 3 30.26a22.5 22.5 0 0 0 0 2.34Z"
    />
    <path
      fill="#b0edcc"
      d="M16.72 4.88a1.67 1.67 0 0 0-1.24.61l-.48.59c-3.17 4-10.48 14-12.19 24.4l.18.09H3a2.53 2.53 0 0 0 3.23-1.51A75.16 75.16 0 0 1 17.32 9a2.87 2.87 0 0 0-.6-4.12Z"
    />
    <path
      fill="#8d6c9f"
      d="M59.24 17.13c-4.29-.26-18.76-.65-29 5.18-1.85-8.68-7.87-15.59-10.77-18.5a2.89 2.89 0 0 0-2.17-.88 3 3 0 0 0-2.14 1C11.34 8 2.4 18.77 2 30.22a23.5 23.5 0 0 0 0 2.44c.61 8.8 5.7 13.58 10.93 14.85a86 86 0 0 0 2.35 10.76v.08a1 1 0 0 0 .93.7 1.06 1.06 0 0 0 .28 0 1 1 0 0 0 .69-1.26v-.12A83.59 83.59 0 0 1 15 47.82h.81a12.09 12.09 0 0 0 4.9-1 19.51 19.51 0 0 0 3.18 4.94 98.47 98.47 0 0 0-6.29 9.53 1 1 0 0 0 .4 1.4 1 1 0 0 0 1.35-.37 96.67 96.67 0 0 1 6-9.08 19.09 19.09 0 0 0 7.42 4.32 17.37 17.37 0 0 0 5 .75 18.79 18.79 0 0 0 11.07-3.78A22.44 22.44 0 0 0 51 52.81c9.9-9.22 10.72-27.62 10.73-33a2.64 2.64 0 0 0-2.49-2.68ZM15.59 45.86c-.28 0-.56 0-.85-.06a69.25 69.25 0 0 1-.6-11.45.94.94 0 0 0 .11-.07l3.13-3.13A1 1 0 0 0 16 29.73l-1.65 1.65c.1-1.21.23-2.34.38-3.35a1 1 0 1 0-2-.29 62.59 62.59 0 0 0-.55 11.38l-1.3-1.3a1 1 0 0 0-1.41 1.41L12.23 42a.93.93 0 0 0 .11.07c.08 1.05.2 2.15.34 3.27C8.47 44 4.54 39.84 4 32.53a21.68 21.68 0 0 1 0-2.23c.38-10.79 9-21.12 12.63-25.05a1 1 0 0 1 .71-.32 1 1 0 0 1 .72.29c3.35 3.36 11.1 12.33 10.73 22.95 0 .74-.11 1.5-.2 2.25C27.26 42 20.76 46 15.59 45.86Zm34 5.49a20.23 20.23 0 0 1-1.91 1.57 16.11 16.11 0 0 1-14.35 2.73 17.11 17.11 0 0 1-6.78-4c1.31-1.69 2.66-3.36 4.09-5h8.56a1 1 0 0 0 0-2h-6.7c2.14-2.28 4.37-4.49 6.74-6.59l4.94-4.38a1 1 0 0 0-1.33-1.5l-4.94 4.38C37 37.4 36.16 38.2 35.3 39v-3.15a1 1 0 0 0-2 0v4.92a1 1 0 0 0 0 .18c-1.3 1.3-2.57 2.63-3.79 4l-.1.11a98.96 98.96 0 0 0-4.26 5.13 17.44 17.44 0 0 1-2.66-4.3c4.65-3 7.35-9.13 8.05-15.25.09-.77.18-1.57.21-2.39a23.65 23.65 0 0 0-.19-3.81c9.77-5.94 24.28-5.56 28.52-5.3a.63.63 0 0 1 .6.64c.01 5.22-.76 22.89-10.07 31.57Z"
    />
    <path
      fill="#8d6c9f"
      d="M54.69 21.09c-.59 0-1.56 0-2.82.13a1 1 0 0 0 .07 2H52c1.21-.08 2.15-.12 2.72-.13a1 1 0 0 0 0-2zm-6.78 1.52a1 1 0 0 0-1.13-.85 56.27 56.27 0 0 0-10.4 2.45 1 1 0 0 0 .33 1.94 1 1 0 0 0 .29-.05 54.3 54.3 0 0 1 10-2.36 1 1 0 0 0 .91-1.13zm-.97 22.87a1 1 0 0 0-1.41 1.41l1.52 1.52A1 1 0 0 0 48.47 47zm-10.61 3.65a1 1 0 0 0-1 1v2.31a1 1 0 0 0 2 0v-2.31a1 1 0 0 0-1-1zm15.27-7.45-2-.84a1 1 0 1 0-.78 1.84l2 .84a1 1 0 1 0 .78-1.84zm-9.15 7.07a1 1 0 0 0-1.85.75l.87 2.14a1 1 0 1 0 1.85-.75zM15.33 21.06a1 1 0 0 0-1.28.61s-.26.72-.57 2a1 1 0 0 0 1.94.47c.29-1.18.51-1.81.51-1.82a1 1 0 0 0-.6-1.26z"
    />
  </svg>
)
export default NaturalFood
