const Poulet = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#efc88e"
      d="M53 16.5c0-.82-.15-1.61-.43-2.33a.865.865 0 0 0-.07-.17l6.5 5-7 1.5h-.03c.03-.23.08-.46.12-.7.58-.97.91-2.09.91-3.3z"
    />
    <path
      fill="#ed7899"
      d="M50 6a2.996 2.996 0 0 1 1.01 5.82A6.45 6.45 0 0 0 46.5 10c-1.71 0-3.26.66-4.42 1.73A3.998 3.998 0 0 1 45 5c1.31 0 2.47.64 3.2 1.62.5-.38 1.12-.62 1.8-.62z"
    />
    <path
      fill="#faefde"
      d="M44.73 33.82 44 34H21l-.78.4c-.98-1.9-1.73-4.05-2.22-6.4h19c4.13 0 6.89 2.73 7.73 5.82z"
    />
    <path
      fill="#faefde"
      d="M44.73 33.82c.18.65.27 1.32.27 1.98 0 1.6-.54 3.3-1.45 4.69-.91 1.4-2.2 2.49-3.69 2.88-8.56 2.21-15.82-1.51-19.64-8.97L21 34h23l.73-.18z"
    />
    <path
      fill="#faefde"
      d="M51.97 20.5c.03-.23.08-.46.12-.7.51-.85.82-1.81.89-2.84a6.43 6.43 0 0 0-.41-2.79.865.865 0 0 0-.07-.17 6.32 6.32 0 0 0-1.49-2.18A6.45 6.45 0 0 0 46.5 10a6.497 6.497 0 0 0-6.5 6.5c0 .15-.01.3-.02.45-.19 2.16-1.69 4.8-2.79 7.18-.72-.08-1.45-.13-2.19-.13-4.47 0-8.58 1.48-11.8 4H37c4.13 0 6.89 2.73 7.73 5.82.18.65.27 1.32.27 1.98 0 1.6-.54 3.3-1.45 4.69-.91 1.4-2.2 2.49-3.69 2.88-8.56 2.21-15.82-1.51-19.64-8.97-.98-1.9-1.73-4.05-2.22-6.4h1.31l-7.77-8.29c-1.09-1.16-3.03-.64-3.4.91L5.9 30.01a8 8 0 0 0 6.14 9.68l8.49 1.79c1.33 2.15 3.13 3.95 5.38 5.26L28 53l4 1s.01-2.5 1.98-5.03c.34.02.67.03 1.02.03.58 0 1.16-.04 1.73-.09L38 54l4-1c0-2.08 0-3.92 1-6.02 4.7-2.5 8.13-7.11 9-12.48 1.22-5.27-.85-8.25-.07-13.73.01-.09.02-.18.04-.27zM48 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
    />
    <circle cx={48} cy={16} r={1} fill="#8d6c9f" />
    <path
      fill="#fff8ee"
      d="M53 16.5c0 .15-.01.31-.02.46a3.009 3.009 0 0 1-3.26-1.82c-.17-.44-.44-.82-.78-1.15-1.32-1.27-3.5-1.28-4.83-.05-.36.33-.65.75-.84 1.21a2.992 2.992 0 0 1-3.29 1.8c.01-.15.02-.3.02-.45a6.497 6.497 0 0 1 6.5-6.5c1.76 0 3.34.69 4.51 1.82A6.32 6.32 0 0 1 52.5 14c.03.05.05.11.07.17.28.72.43 1.51.43 2.33z"
    />
    <path
      fill="#8d6c9f"
      d="M32 55c-.081 0-.163-.01-.243-.03l-4-1a.997.997 0 0 1-.706-.653l-1.972-5.908a16.318 16.318 0 0 1-5.167-5.036l-8.079-1.703a8.944 8.944 0 0 1-5.753-3.992 8.931 8.931 0 0 1-1.153-6.898l2.241-9.391a3 3 0 0 1 5.102-1.362l7.771 8.291a1 1 0 1 1-1.459 1.367l-7.77-8.29a.97.97 0 0 0-.98-.279.983.983 0 0 0-.718.738l-2.24 9.39a6.946 6.946 0 0 0 .897 5.364 6.96 6.96 0 0 0 4.477 3.105l8.49 1.79c.267.057.5.22.644.452a14.387 14.387 0 0 0 5.033 4.922c.21.122.368.317.445.548l1.916 5.739 2.376.594a10.582 10.582 0 0 1 2.04-4.401c.202-.26.519-.402.849-.384.882.054 1.839.008 2.603-.058a.987.987 0 0 1 1.057.754l1.028 4.119 2.274-.568c.015-1.85.133-3.647 1.095-5.668a1 1 0 0 1 .433-.453c4.507-2.397 7.678-6.793 8.482-11.758.62-2.686.356-4.723.076-6.88-.263-2.027-.534-4.124-.149-6.831.01-.094.026-.22.054-.347a4.92 4.92 0 0 1 .066-.402l.043-.244c.021-.124.064-.242.128-.35.511-.852.769-1.79.769-2.787a5.4 5.4 0 0 0-.362-1.967c-.026-.043-.044-.107-.063-.154a5.282 5.282 0 0 0-1.254-1.834 5.506 5.506 0 0 0-7.563-.079A5.526 5.526 0 0 0 41 16.5c0 2.05-1.125 4.367-2.213 6.608-.236.486-.471.969-.689 1.441-.181.391-.59.619-1.018.574A18.766 18.766 0 0 0 35 25c-4.091 0-8.063 1.345-11.184 3.787a.999.999 0 1 1-1.233-1.574C26.055 24.496 30.465 23 35 23c.51 0 1.034.022 1.585.066l.402-.832C37.977 20.197 39 18.09 39 16.5c0-2.08.875-4.085 2.399-5.502A7.504 7.504 0 0 1 46.5 9c1.953 0 3.802.746 5.205 2.101a7.252 7.252 0 0 1 1.7 2.47c.056.108.088.207.112.277.316.808.483 1.715.483 2.652 0 1.28-.33 2.538-.957 3.649l-.017.093c-.024.131-.048.258-.064.386-.027.143-.032.196-.038.25-.351 2.471-.098 4.43.148 6.324.29 2.238.59 4.553-.098 7.523-.873 5.402-4.308 10.251-9.203 12.974C43 49.474 43 51.042 43 53c0 .459-.313.858-.757.97l-4 1a1.002 1.002 0 0 1-1.213-.727l-1.066-4.272c-.476.028-1.001.037-1.495.022C33.025 52.075 33 53.983 33 54.004A.998.998 0 0 1 32 55z"
    />
    <path
      fill="#8d6c9f"
      d="M51.01 12.82a1 1 0 0 1-.334-1.942A1.993 1.993 0 0 0 52 9c0-1.103-.897-2-2-2-.535 0-.945.227-1.195.416a1 1 0 0 1-1.407-.199A2.969 2.969 0 0 0 45 6c-1.654 0-3 1.346-3 3 0 .758.289 1.486.813 2.05a1 1 0 1 1-1.465 1.361A4.997 4.997 0 0 1 40 9c0-2.757 2.243-5 5-5 1.282 0 2.48.481 3.398 1.345A3.879 3.879 0 0 1 50 5a3.996 3.996 0 0 1 1.343 7.763.992.992 0 0 1-.333.057zM29.999 59a1 1 0 0 1-.992-.89l-.5-4.5a1 1 0 1 1 1.987-.22l.5 4.5a1 1 0 0 1-.995 1.11zM40.999 59a1 1 0 0 1-.992-.89l-.5-4.5a1 1 0 1 1 1.987-.22l.5 4.5a1 1 0 0 1-.995 1.11z"
    />
    <path
      fill="#8d6c9f"
      d="M34.999 60a.988.988 0 0 1-.495-.132c-1.428-.815-4.491-1.275-5.853-.109a1 1 0 1 1-1.302-1.517c2.175-1.865 6.129-1.261 8.147-.109A1 1 0 0 1 34.999 60zM45.999 60a.988.988 0 0 1-.495-.132c-1.429-.815-4.491-1.275-5.853-.109a1 1 0 0 1-1.302-1.517c2.176-1.865 6.129-1.261 8.147-.109A1 1 0 0 1 45.999 60z"
    />
    <path
      fill="#fff8ee"
      d="M42.365 34H23a3 3 0 0 1-2.769-1.846L18.5 28h21l3.633 4.36a1 1 0 0 1-.768 1.64z"
    />
    <path
      fill="#8d6c9f"
      d="M51.999 21.5a1.001 1.001 0 0 1-.208-1.978l4.887-1.048-4.787-3.682a1 1 0 1 1 1.219-1.586l6.5 5a1.002 1.002 0 0 1-.4 1.771l-7 1.5a.96.96 0 0 1-.211.023zM35.132 44.989c-6.663 0-12.469-3.624-15.801-10.132-1.027-1.992-1.805-4.23-2.31-6.653A.997.997 0 0 1 18 27h19c4.775 0 7.808 3.294 8.695 6.558.202.728.305 1.484.305 2.242 0 1.762-.588 3.671-1.613 5.238-1.102 1.694-2.62 2.867-4.274 3.3-1.695.437-3.363.65-4.981.651zM19.258 29c.457 1.784 1.077 3.442 1.851 4.942l.001.002c3.748 7.319 10.662 10.481 18.5 8.458 1.168-.306 2.271-1.179 3.102-2.457C43.531 38.693 44 37.183 44 35.8c0-.577-.079-1.153-.233-1.712C43.128 31.738 40.974 29 37 29H19.258z"
    />
    <path
      fill="#8d6c9f"
      d="M25 36a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zM30 36a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zM35 36a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zM40 36a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zM13 31a.997.997 0 0 1-.707-.293l-5.5-5.5a.999.999 0 1 1 1.414-1.414l5.5 5.5A.999.999 0 0 1 13 31z"
    />
  </svg>
)
export default Poulet
