const MachineALaver = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path fill="#85cbf8" d="M7 3h50v56H7z" />
    <path
      fill="#ed7899"
      d="M54.5 59h-45C8.1 59 7 57.9 7 56.5S8.1 54 9.5 54h45c1.4 0 2.5 1.1 2.5 2.5S55.9 59 54.5 59z"
    />
    <path
      fill="#ace3ff"
      d="M54 13H10c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3h44c1.7 0 3 1.3 3 3v4c0 1.7-1.3 3-3 3z"
    />
    <path fill="#f9e3ae" d="M51 7h6v6h-6z" />
    <path fill="#f3f3f3" d="M31 17a16 16 0 1 0 0 32 16 16 0 1 0 0-32Z" />
    <path
      fill="#bbdef9"
      d="M31 21c-4.4 0-8.3 2.4-10.4 5.9 1.5 1.6 2.4 3.7 2.4 6.1s-.9 4.5-2.4 6.1c2.1 3.5 6 5.9 10.4 5.9 6.6 0 12-5.4 12-12s-5.4-12-12-12z"
    />
    <path fill="#d2edff" d="M31 28a5 5 0 1 0 0 10 5 5 0 1 0 0-10Z" />
    <path
      fill="#f3f3f3"
      d="M28 27a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm6.5 8a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3z"
    />
    <path
      fill="#8d6c9f"
      d="M55 2H9C7.3 2 6 3.3 6 5v52c0 1.7 1.3 3 3 3h46c1.7 0 3-1.3 3-3V5c0-1.7-1.3-3-3-3zm1 10h-4V8h4v4zm-4-6c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h4v43c0 .6-.4 1-1 1H9c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h46c.6 0 1 .4 1 1v1h-4z"
    />
    <path
      fill="#8d6c9f"
      d="M12 6a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm6 0a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm29 2h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1zm-32 6c.6 0 1-.4 1-1s-.4-1-1-1h-4c-.6 0-1 .4-1 1s.4 1 1 1h4zm32-2H19c-.6 0-1 .4-1 1s.4 1 1 1h28c.6 0 1-.4 1-1s-.4-1-1-1zm-.8 28.7c.3-.5.5-1.1.7-1.7.2-.5-.1-1.1-.6-1.3-.5-.2-1.1.1-1.3.6-.2.5-.4 1-.7 1.5-.2.5-.1 1.1.4 1.3.1.1.3.1.5.1.5 0 .8-.2 1-.5z"
    />
    <path
      fill="#8d6c9f"
      d="M31 16c-9.4 0-17 7.6-17 17s7.6 17 17 17c4.7 0 9.2-2 12.4-5.4.4-.4.4-1-.1-1.4s-1-.4-1.4 0c-2.8 3-6.8 4.8-11 4.8-8.3 0-15-6.7-15-15s6.7-15 15-15 15 6.7 15 15v1.1c0 .6.4 1 .9 1.1.6 0 1-.4 1.1-.9V33c.1-9.4-7.5-17-16.9-17z"
    />
    <path
      fill="#8d6c9f"
      d="M19.6 39.3C21.9 43.4 26.3 46 31 46c7.2 0 13-5.8 13-13s-5.8-13-13-13c-4.7 0-9.1 2.6-11.4 6.7-.2.4-.1.9.2 1.2C21.2 29.2 22 31 22 33s-.8 3.7-2.2 5.1c-.3.3-.4.8-.2 1.2zM24 33c0-2.2-.8-4.3-2.3-5.9 2-3.1 5.5-5.1 9.3-5.1 6.1 0 11 4.9 11 11s-4.9 11-11 11c-3.8 0-7.2-1.9-9.3-5.1 1.5-1.6 2.3-3.7 2.3-5.9zM12 52c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 0c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 0c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 0c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 0c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 0c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 0c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 0c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1zm5 4c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .6.4 1 1 1z"
    />
  </svg>
)
export default MachineALaver
