const Broccoli = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#b0edcc"
      d="M39 60H25c1-10-11-27-11-27h7l8 15V33h5l2 14 7-14h8S39 51 39 60z"
    />
    <path
      fill="#5dbc9d"
      d="M50.474 15.241c-1.498-4.552-5.876-7.966-11.061-7.966-2.996 0-5.761 1.138-7.72 2.959A9.39 9.39 0 0 0 23.283 5c-5.07 0-9.217 4.097-9.217 9.103 0 .455 0 .797.115 1.252C9.572 15.81 6 19.679 6 24.345c0 5.007 4.148 9.103 9.217 9.103.576 0 1.152-.114 1.613-.114C18.674 36.179 21.9 38 25.587 38c3.341 0 6.452-1.593 8.296-4.097 1.498 1.138 3.457 1.821 5.53 1.821 2.65 0 5.07-1.138 6.798-2.959a9.494 9.494 0 0 0 3.572.683c5.07 0 9.217-4.097 9.217-9.103 0-4.779-3.802-8.762-8.526-9.104z"
    />
    <circle cx={40} cy={17} r={10} fill="#72caaf" />
    <circle cx={50.5} cy={24.5} r={8.5} fill="#5dbc9d" />
    <circle cx={23} cy={14} r={9} fill="#72caaf" />
    <circle cx={26} cy={28} r={10} fill="#72caaf" />
    <path
      fill="#8d6c9f"
      d="M39 61H25a1 1 0 0 1-1-1c0-14.153-12.559-26.152-12.686-26.271a1.001 1.001 0 0 1 1.371-1.457c.531.5 12.752 12.162 13.296 26.729h12.173c3.061-17.359 12.764-26.146 13.182-26.518a.999.999 0 0 1 1.329 1.495c-.099.088-9.908 9.008-12.678 26.182A1 1 0 0 1 39 61z"
    />
    <path
      fill="#8d6c9f"
      d="M29 49c-.383 0-.74-.221-.907-.579-2.879-6.201-5.828-9.742-5.857-9.777a.999.999 0 0 1 1.529-1.288c.1.118 1.979 2.368 4.235 6.459V38a1 1 0 1 1 2 0v10a1.001 1.001 0 0 1-1 1zm7.001-1a1 1 0 0 1-.987-.836l-.833-5a.998.998 0 0 1 .822-1.15.996.996 0 0 1 1.15.822l.193 1.161c2.348-6.222 3.824-7.606 4.01-7.762a.997.997 0 0 1 1.408.121.999.999 0 0 1-.099 1.39c-.076.076-1.883 1.962-4.716 10.566a.997.997 0 0 1-.948.688zm-1.502-9a1 1 0 0 1-.985-.836l-.5-3a.999.999 0 0 1 .822-1.15.994.994 0 0 1 1.15.822l.5 3A.999.999 0 0 1 34.499 39z"
    />
    <path
      fill="#8d6c9f"
      d="M25.587 39c-3.726 0-7.143-1.72-9.259-4.63l-.112.012a9.741 9.741 0 0 1-.998.066C9.584 34.448 5 29.916 5 24.345c0-4.837 3.403-8.899 8.068-9.833-.003-.134-.003-.269-.003-.408C13.065 8.532 17.648 4 23.282 4a10.43 10.43 0 0 1 8.69 4.704c2.093-1.555 4.74-2.429 7.44-2.429 5.176 0 9.931 3.273 11.789 8.037C56.186 14.999 60 19.284 60 24.345c0 5.571-4.584 10.104-10.218 10.104a10.5 10.5 0 0 1-3.317-.527c-1.927 1.789-4.462 2.804-7.052 2.804-1.901 0-3.762-.52-5.332-1.478C31.982 37.612 28.875 39 25.587 39zm-8.757-6.665a1 1 0 0 1 .839.456C19.377 35.427 22.337 37 25.587 37c2.944 0 5.814-1.414 7.49-3.689a1.001 1.001 0 0 1 1.41-.203c1.374 1.043 3.123 1.617 4.926 1.617 2.295 0 4.451-.94 6.072-2.647a.994.994 0 0 1 1.093-.241 8.457 8.457 0 0 0 3.204.612c4.531 0 8.218-3.635 8.218-8.104 0-4.237-3.338-7.798-7.599-8.106a1.001 1.001 0 0 1-.878-.685c-1.409-4.285-5.566-7.278-10.11-7.278-2.633 0-5.198.981-7.039 2.691a1.013 1.013 0 0 1-.864.251 1.004 1.004 0 0 1-.715-.545C29.41 7.834 26.462 6 23.282 6c-4.53 0-8.217 3.635-8.217 8.104 0 .419 0 .672.085 1.007a1 1 0 0 1-.871 1.24C10.129 16.761 7 20.197 7 24.345c0 4.469 3.687 8.104 8.218 8.104.27 0 .538-.029.792-.056.293-.032.571-.058.82-.058zm25.334-6.892c-.406 0-.789-.25-.938-.653-.521-1.409-.517-2.751.012-3.882.526-1.127 1.555-2 2.974-2.523a.997.997 0 0 1 1.284.591.999.999 0 0 1-.591 1.284c-.928.344-1.552.847-1.855 1.495-.302.646-.284 1.433.052 2.342a.998.998 0 0 1-.938 1.346z"
    />
    <path
      fill="#8d6c9f"
      d="M32.53 17.173c-.08 0-.161-.01-.242-.029l-1.94-.482a1 1 0 0 1 .483-1.941l1.94.482a1 1 0 0 1-.241 1.97zM29.957 21.957a.997.997 0 0 1-.707-.293l-1.414-1.414a.999.999 0 1 1 1.414-1.414l1.414 1.414a.999.999 0 0 1-.707 1.707zM25.172 24.529c-.448 0-.856-.304-.97-.759l-.482-1.94a1 1 0 0 1 1.941-.483l.482 1.94a1 1 0 0 1-.971 1.242z"
    />
  </svg>
)
export default Broccoli
