const Rocket = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#f6d397"
      d="m14.28 42.95-1 .31A9.89 9.89 0 0 0 6.58 50a34.25 34.25 0 0 0-1.09 7.36 1 1 0 0 0 1 1 34.31 34.31 0 0 0 7.32-1.08 9.88 9.88 0 0 0 6.76-6.74l.33-1.11"
    />
    <path
      fill="#faefde"
      d="m15.43 45.37-.48.15a4.62 4.62 0 0 0-3.12 3.15 16 16 0 0 0-.51 3.44.46.46 0 0 0 .48.48 16 16 0 0 0 3.42-.5 4.61 4.61 0 0 0 3.16-3.15l.16-.52"
    />
    <path
      fill="#f9e3ae"
      d="M25.28 24.36 9.83 28.2a4 4 0 0 0-2.08 1.29l-3.29 3.86A1 1 0 0 0 5.22 35H16m23.64 3.72L35.8 54.18a4 4 0 0 1-1.29 2.08l-3.86 3.28a1 1 0 0 1-1.65-.76V48"
    />
    <path
      fill="#acb7d0"
      d="m16.84 37.31-2.32 4.27a2 2 0 0 0 .48 2.09l5.43 5.43a2 2 0 0 0 2.08.43l4.14-2.18"
    />
    <path
      fill="#c2cde7"
      d="m61.3 5.08-.9 6.14a30 30 0 0 1-10.12 18.4l-20.5 17.63a2 2 0 0 1-2.72-.1L17 37a2 2 0 0 1-.1-2.72l17.6-20.46A30 30 0 0 1 52.9 3.71l6.1-.9a2 2 0 0 1 2.3 2.27Z"
    />
    <path
      fill="#d5e0f3"
      d="M17 37.05a2 2 0 0 1-.1-2.72l17.6-20.51A30 30 0 0 1 52.9 3.71L21.23 37a3 3 0 0 1-4.23.05Z"
    />
    <path
      fill="#ed7899"
      d="m61.3 5.08-.9 6.14A30 30 0 0 1 57.18 21c-2.09-2.09-11.34-10.84-14.4-13.9A30 30 0 0 1 52.9 3.71l6.1-.9a2 2 0 0 1 2.3 2.27Z"
    />
    <path fill="#ace3ff" d="M43 16a5 5 0 1 0 0 10 5 5 0 1 0 0-10Z" />
    <path
      fill="#8d6c9f"
      d="M61.45 2.67a3 3 0 0 0-2.56-.85l-6.13.9a31 31 0 0 0-19 10.45l-8.83 10.25-15.35 3.81A5 5 0 0 0 7 28.85L3.7 32.7A2 2 0 0 0 5.22 36h10.19a3 3 0 0 0 .38 1.14l-2.16 4a1.06 1.06 0 0 0-.07.16 3.06 3.06 0 0 0-.15.9l-.45.14a10.82 10.82 0 0 0-7.34 7.43 35.31 35.31 0 0 0-1.13 7.58 2 2 0 0 0 2 2.08h.08a35.38 35.38 0 0 0 7.55-1.12 10.94 10.94 0 0 0 7.46-7.42l.07-.23h.16a3.28 3.28 0 0 0 1-.17l.15-.06 4-2.1a3 3 0 0 0 1 .37v10.08a2 2 0 0 0 1.16 1.82 2 2 0 0 0 .84.19 2 2 0 0 0 1.29-.48l3.9-3.31a5 5 0 0 0 1.61-2.6l3.75-15.08 10.42-9a31 31 0 0 0 10.45-19l.9-6.14a3 3 0 0 0-.83-2.51ZM8.5 30.14a3 3 0 0 1 1.56-1L22.67 26l-6.57 7.68a2.93 2.93 0 0 0-.23.32H5.22Zm11.16 20.15a8.93 8.93 0 0 1-6.07 6.06 33.85 33.85 0 0 1-7.1 1.05 33.37 33.37 0 0 1 1.05-7.13 8.83 8.83 0 0 1 6-6.05l.49-.15a3.09 3.09 0 0 0 .27.31l5.43 5.43h.05Zm2.48-1.69a1 1 0 0 1-1-.2L15.71 43a1 1 0 0 1-.27-1l1.77-3.25 8.17 8.17Zm12.69 5.33a3 3 0 0 1-1 1.56L30 58.78V48.32a3 3 0 0 0 .43-.31l7.46-6.42Zm25.49-49-.9 6.13a29 29 0 0 1-9.78 17.78l-10.44 9a1 1 0 0 0-.37.32l-9.7 8.34a1 1 0 0 1-1.36-.05l-10.1-10.1a1 1 0 0 1-.05-1.35l17.64-20.52a29 29 0 0 1 7.56-6.24l4.47 4.47a1 1 0 0 0 1.41-1.41l-4-4A29 29 0 0 1 53 4.7l6.13-.9h.15a1 1 0 0 1 1 1.15Z"
    />
    <path
      fill="#8d6c9f"
      d="M36 26.72a1 1 0 0 0-1.41 1.41L36 29.54a1 1 0 0 0 1.41-1.41zm-3.55 3.53A1 1 0 0 0 31 31.67l1.42 1.41a1 1 0 0 0 1.41-1.42zm-3.54 3.54a1 1 0 0 0-1.41 1.41l1.41 1.41a1 1 0 0 0 1.41-1.41zm-3.53 3.53A1 1 0 0 0 24 38.74l1.41 1.41a1 1 0 0 0 1.41-1.41zM43 15a6 6 0 1 0 6 6 6 6 0 0 0-6-6zm0 10a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm8.71-10.71a1 1 0 0 0-1.41 1.41l3 3a1 1 0 0 0 1.41-1.41z"
    />
  </svg>
)
export default Rocket
