const Soleil = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#f9e3ae"
      d="M11.412 27.878a21 21 0 1 0 41.184 8.237 21 21 0 1 0-41.184-8.237Z"
    />
    <path
      fill="#faefde"
      d="M36.12 11.41A20.93 20.93 0 0 0 18 16.35a3 3 0 0 0 .26 4.71 3 3 0 0 0 3.74-.24A15 15 0 0 1 45.73 26a3 3 0 0 0 3.58 1.74 3 3 0 0 0 2-4 20.94 20.94 0 0 0-15.19-12.33Z"
    />
    <path
      fill="#f6d397"
      d="M24.51 51.62a20.93 20.93 0 0 0 18.67-1.86 3 3 0 0 0 .53-4.69 3 3 0 0 0-3.71-.39 15 15 0 0 1-22.54-9.06 3 3 0 0 0-3.24-2.31 3 3 0 0 0-2.59 3.62 20.94 20.94 0 0 0 12.88 14.69Z"
    />
    <path
      fill="#8d6c9f"
      d="M32 10a22 22 0 1 0 22 22 22 22 0 0 0-22-22Zm0 42a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z"
    />
    <path
      fill="#8d6c9f"
      d="M42.08 19.58a1 1 0 1 0-1.26 1.55 14.15 14.15 0 0 1 1.52 1.43 1 1 0 0 0 1.48-1.35 16.2 16.2 0 0 0-1.74-1.63zM21.9 21.91a1 1 0 0 0 .67-.26A14 14 0 0 1 37.09 19a1 1 0 0 0 .73-1.86 16 16 0 0 0-16.6 3.08 1 1 0 0 0 .67 1.74zM32 8a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1zm0 48a1 1 0 0 0-1 1v6a1 1 0 0 0 2 0v-6a1 1 0 0 0-1-1zM8 32a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h6a1 1 0 0 0 1-1zm55-1h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM21.71 9.19a1 1 0 1 0 1.85-.75l-1.5-3.71a1 1 0 1 0-1.85.75zm20.58 45.62a1 1 0 1 0-1.85.75l1.5 3.71a1 1 0 1 0 1.85-.75zM9.75 41a1 1 0 0 0-1.3-.55l-3.71 1.5a1 1 0 1 0 .75 1.85l3.71-1.5a1 1 0 0 0 .55-1.3zm44.5-18a1 1 0 0 0 1.3.55l3.71-1.5a1 1 0 0 0-.75-1.85l-3.71 1.5a1 1 0 0 0-.55 1.3zM41 9.75a1 1 0 0 0 1.3-.55l1.5-3.71a1 1 0 1 0-1.85-.75l-1.5 3.71a1 1 0 0 0 .55 1.3zm-18 44.5a1 1 0 0 0-1.3.55l-1.5 3.71a1 1 0 1 0 1.85.75l1.5-3.71a1 1 0 0 0-.55-1.3zm36.26-12.31-3.71-1.5a1 1 0 0 0-.75 1.85l3.71 1.5a1 1 0 0 0 .75-1.85zM4.74 22.06l3.71 1.5a1 1 0 0 0 .75-1.85l-3.71-1.5a1 1 0 0 0-.75 1.85zM13.62 15A1 1 0 0 0 15 13.62l-4.21-4.25a1 1 0 0 0-1.41 1.41zm36.76 34A1 1 0 0 0 49 50.38l4.24 4.24a1 1 0 0 0 1.41-1.41zm-36.76 0-4.25 4.21a1 1 0 1 0 1.41 1.41L15 50.38A1 1 0 0 0 13.62 49zm36.06-33.68a1 1 0 0 0 .71-.29l4.24-4.24a1 1 0 0 0-1.41-1.41L49 13.62a1 1 0 0 0 .71 1.71zM32 44a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zm5.8-.32a1 1 0 1 0-1.85.75l.75 1.85a1 1 0 1 0 1.85-.75zm-10.3-.55a1 1 0 0 0-1.3.55l-.75 1.85a1 1 0 1 0 1.85.75l.75-1.85a1 1 0 0 0-.55-1.3zm-5.4-2.64-1.41 1.41a1 1 0 1 0 1.41 1.41l1.41-1.41a1 1 0 0 0-1.41-1.41zm-1.1-3.8a1 1 0 0 0-1.31-.53l-1.84.78a1 1 0 1 0 .78 1.84l1.79-.78a1 1 0 0 0 .58-1.31z"
    />
  </svg>
)
export default Soleil
