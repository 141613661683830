const Camper = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path fill="#85cbf8" d="M38 38H3v7.165C3 46.178 3.822 47 4.835 47H38v-9z" />
    <path
      fill="#8d6c9f"
      d="M39 48H4.836A2.839 2.839 0 0 1 2 45.164V37h37v11zM4 39v6.164c0 .461.375.836.836.836H37v-7H4z"
    />
    <path
      fill="#fff8ee"
      d="M51.474 12.316A5 5 0 0 0 47.255 10H9.167A6.167 6.167 0 0 0 3 16.167V38h35V23a2 2 0 0 1 2-2h13.357c1.578 0 2.535-1.742 1.687-3.074l-3.57-5.61z"
    />
    <path
      fill="#8d6c9f"
      d="M39 39H2V16.167A7.175 7.175 0 0 1 9.167 9h38.088a5.975 5.975 0 0 1 5.063 2.778l3.57 5.611a2.96 2.96 0 0 1 .099 3.054A2.961 2.961 0 0 1 53.356 22H40c-.552 0-1 .449-1 1v16zM4 37h33V23c0-1.654 1.346-3 3-3h13.356a.981.981 0 0 0 .877-.519.978.978 0 0 0-.033-1.018l-3.57-5.611a3.982 3.982 0 0 0-3.375-1.853H9.167A5.174 5.174 0 0 0 4 16.167V37z"
    />
    <path
      fill="#85cbf8"
      d="m59.684 31.446-5.846-8.08A6.69 6.69 0 0 0 48.732 21H40a2 2 0 0 0-2 2v24h21a2 2 0 0 0 2-2v-9.964c0-1.314-.656-2.723-1.316-3.59z"
    />
    <path fill="#bbdef9" d="M54.542 25H46v9h15M11 16h18v12H11z" />
    <path
      fill="#8d6c9f"
      d="M47 17h-8a1 1 0 1 1 0-2h8a1 1 0 1 1 0 2zm10 22h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2zm-7 0H38a1 1 0 1 1 0-2h12a1 1 0 1 1 0 2z"
    />
    <path fill="#c2cde7" d="M3 47h12v-4H3a2 2 0 1 0 0 4z" />
    <path
      fill="#8d6c9f"
      d="M16 48H3c-1.654 0-3-1.346-3-3s1.346-3 3-3h13v6zM3 44a1 1 0 0 0 0 2h11v-2H3z"
    />
    <path
      fill="#7bbeeb"
      d="M22 46h20v-2c0-1.656-1.344-3-3-3H25c-1.656 0-3 1.344-3 3v2z"
    />
    <path
      fill="#efd8be"
      d="M4 37h33v-1c0-1.656-1.344-3-3-3H7c-1.656 0-3 1.344-3 3v1z"
    />
    <path
      fill="#acb7d0"
      d="M21 48c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6z"
    />
    <path
      fill="#8d6c9f"
      d="M15 55c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7-3.141 7-7 7zm0-12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
    />
    <path fill="#8d6c9f" d="M17 48a2 2 0 1 1-3.999.001A2 2 0 0 1 17 48z" />
    <path
      fill="#e3f2ff"
      d="M18.5 16 10 24.5V28h.876l12.106-12zm8.626 0L15.025 28h6.545L29 20.57V16zM54.7 25h-4.2L46 29.5v4.359l8.766-8.766zM50 33.141V34h5.57l3.246-3.246-2.683-3.75z"
    />
    <path
      fill="#8d6c9f"
      d="M29 28H11a1 1 0 0 1-1-1V16a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1zm-17-2h16v-9H12v9z"
    />
    <path
      fill="#8d6c9f"
      d="M11 22a1 1 0 0 1-.005-2l17.469-.094h.005a1 1 0 0 1 .005 2L11.005 22H11zm49 13H46a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-3v7h13a1 1 0 1 1 0 2z"
    />
    <path
      fill="#8d6c9f"
      d="M59 48H37V23c0-1.654 1.346-3 3-3h8.731c2.266 0 4.404.991 5.869 2.72l5.887 8.131C61.22 31.816 62 33.42 62 35.036V45c0 1.654-1.346 3-3 3zm-20-2h20a1 1 0 0 0 1-1v-9.964c0-1.054-.559-2.258-1.111-2.984l-.016-.02-5.846-8.08C51.991 22.733 50.408 22 48.731 22H40c-.552 0-1 .449-1 1v23z"
    />
    <path fill="#c2cde7" d="M61 47H49v-4h12a2 2 0 1 1 0 4z" />
    <path
      fill="#8d6c9f"
      d="M61 48H48v-6h13c1.654 0 3 1.346 3 3s-1.346 3-3 3zm-11-2h11a1 1 0 0 0 0-2H50v2z"
    />
    <path
      fill="#acb7d0"
      d="M55 48c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6z"
    />
    <path
      fill="#8d6c9f"
      d="M49 55c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7-3.141 7-7 7zm0-12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
    />
    <path
      fill="#8d6c9f"
      d="M51 48a2 2 0 1 1-3.999.001A2 2 0 0 1 51 48zM27 32v2c0 .551.449 1 1 1s1-.449 1-1v-2c0-.551-.449-1-1-1s-1 .449-1 1zM32 32v2c0 .551.449 1 1 1s1-.449 1-1v-2c0-.551-.449-1-1-1s-1 .449-1 1zM22 32v2c0 .551.449 1 1 1s1-.449 1-1v-2c0-.551-.449-1-1-1s-1 .449-1 1zM17 32v2c0 .551.449 1 1 1s1-.449 1-1v-2c0-.551-.449-1-1-1s-1 .449-1 1zM12 32v2c0 .551.449 1 1 1s1-.449 1-1v-2c0-.551-.449-1-1-1s-1 .449-1 1zM7 32v2c0 .551.449 1 1 1s1-.449 1-1v-2c0-.551-.449-1-1-1s-1 .449-1 1z"
    />
  </svg>
)
export default Camper
