const Scooter = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <path
      fill="#c2cde7"
      d="M14 41a7 7 0 1 0 0 14 7 7 0 1 0 0-14zm38 0a7 7 0 1 0 0 14 7 7 0 1 0 0-14z"
    />
    <path
      fill="#8d6c9f"
      d="M14 56c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-14c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm38 14c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-14c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z"
    />
    <path
      fill="#8d6c9f"
      d="M52 45a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm-38 0a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"
    />
    <path fill="#cda1a7" d="m33 23-14.03-1.403A5.43 5.43 0 0 0 13 27h20v-4z" />
    <path
      fill="#8d6c9f"
      d="M33 28H13a1 1 0 0 1-1-1c0-1.813.771-3.551 2.115-4.768a6.425 6.425 0 0 1 4.955-1.631l14.03 1.404A1 1 0 0 1 34 23v4a1 1 0 0 1-1 1zm-18.889-2H32v-2.095l-13.129-1.313a4.362 4.362 0 0 0-3.414 1.124A4.38 4.38 0 0 0 14.111 26z"
    />
    <path
      fill="#72caaf"
      d="m47 15-2.296-.022c-1.592.332-2.146 2.327-.945 3.422A12.952 12.952 0 0 1 48 28c0 7.18-5.82 13-13 13h-2.526a2 2 0 0 1-1.956-2.419L33 27H16C7.716 27 1 33.716 1 42v3l44.46.023C51.176 41.503 55 35.206 55 28c0-5.24-8-13-8-13z"
    />
    <path
      fill="#fff8ee"
      d="M46.4 21.778c1.351.719 2.605 1.759 3.7 2.289 1.256.609 2.822 1.028 4.322 1.228C52.602 20.447 47 15 47 15l-2.296-.022c-1.592.332-2.146 2.327-.945 3.422a13.004 13.004 0 0 1 2.641 3.378zM19 45c1.657 0 4-5 4-9a3 3 0 0 0-3-3c-6 0-19-1-19 12"
    />
    <path
      fill="#8d6c9f"
      d="M45 46H1a1 1 0 0 1-1-1v-3c0-8.822 7.178-16 16-16h17a1 1 0 0 1 .977 1.21l-2.481 11.581A1 1 0 0 0 32.474 40H36a1 1 0 1 1 0 2h-3.526c-.91 0-1.76-.406-2.332-1.113a2.992 2.992 0 0 1-.601-2.515L31.763 28H16C8.28 28 2 34.28 2 42v2h43a1 1 0 1 1 0 2z"
    />
    <path
      fill="#fff8ee"
      d="M62.513 44.821a8.318 8.318 0 0 0-.082-.254c-1.314-3.928-4.76-6.892-8.864-7.458-.299-.041-.591-.044-.886-.061-.044.77-.234 1.529-.619 2.197-1.131 1.966-2.7 3.396-4.489 4.533A13.304 13.304 0 0 1 52 43c3.076 0 5.854 1.264 7.847 3.301 1.155 1.179 3.154.097 2.666-1.48z"
    />
    <path
      fill="#8d6c9f"
      d="M61.006 47.804A2.607 2.607 0 0 1 59.133 47 9.905 9.905 0 0 0 52 44c-2.648 0-5.453 1.319-6.536 1.886l-.928-1.771C45.75 43.479 48.908 42 52 42c3.248 0 6.289 1.279 8.562 3.602a.61.61 0 0 0 .772.105c.156-.093.322-.271.224-.591l-.075-.232c-1.209-3.612-4.369-6.275-8.052-6.785-.22-.03-.438-.053-.655-.069l.148-1.994c.258.019.518.047.78.083 4.43.612 8.227 3.804 9.675 8.132l.089.274a2.474 2.474 0 0 1-1.11 2.901 2.638 2.638 0 0 1-1.352.378zM35 42a1 1 0 1 1 0-2c6.617 0 12-5.383 12-12 0-3.31-1.588-6.571-4.368-9.001L33 19a1 1 0 1 1 0-2l10.361-.001.278.231C47.046 20.062 49 23.988 49 28c0 7.72-6.28 14-14 14z"
    />
    <path
      fill="#8d6c9f"
      d="M40 20h-5a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1zm-4-2h3-3zm9.001 28a1.001 1.001 0 0 1-.526-1.852C50.351 40.53 54 34.343 54 28c0-5.756-6.037-10.943-7.351-12.003l-1.829-.018a.984.984 0 0 0-.673.692.962.962 0 0 0 .286.989A14.026 14.026 0 0 1 49 28c0 7.72-6.28 14-14 14a1 1 0 1 1 0-2c6.617 0 12-5.383 12-12 0-3.362-1.427-6.593-3.914-8.86a2.974 2.974 0 0 1-.867-2.996 2.99 2.99 0 0 1 2.281-2.145l.105-.021 2.732.025.263.197c.343.257 8.4 6.385 8.4 13.8 0 7.032-4.014 13.872-10.476 17.852a.998.998 0 0 1-.523.148zM19 46a1 1 0 0 1-.015-2c.851-.199 3.015-4.15 3.015-8 0-1.103-.897-2-2-2-.104 0-.258-.004-.456-.009-7.223-.151-16.145 1.11-17.564 8.205a1 1 0 0 1-1.961-.393c1.319-6.597 8.265-10.08 19.572-9.812L20 32c2.206 0 4 1.794 4 4 0 3.857-2.288 10-5 10zm-.021-1.998z"
    />
    <path fill="#f9dd8f" d="M55 18h-3.5a2.5 2.5 0 1 1 0-5H55v5z" />
    <path
      fill="#8d6c9f"
      d="M55 19h-3.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5H55a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-3.5-5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5H54v-3h-2.5zm3.125 12.322c-.083 0-.167-.01-.25-.031a22.557 22.557 0 0 1-9.412-5.053 1.002 1.002 0 0 1-.075-1.413 1.005 1.005 0 0 1 1.413-.075 20.53 20.53 0 0 0 8.572 4.604c.535.138.857.683.72 1.218-.116.45-.523.75-.968.75zM25 52a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zm5 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zm5 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zm5 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1z"
    />
  </svg>
)
export default Scooter
