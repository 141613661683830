const CanardEnCaoutchouc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    baseProfile="basic"
    clipRule="evenodd"
    {...props}>
    <path
      fill="#fae3ae"
      fillRule="evenodd"
      d="M49.795 25.533c4.792-4.093 4.269-6.04 3.661-9.907-.788-5.013-4.979-9-10.015-9.56-6.921-.76-12.781 4.64-12.781 11.413 0 1.413.528 4.267 2.431 7.467.78 1.32.609 3.053-2.091 3.053H19c-4.048 0-9-8-9-8s-5 7.893-5 16c0 12.16 7.852 20 20 20h14c9.241 0 16.2-6.747 16-16-.089-4.16-3.188-8.24-5.317-10.64-.966-1.093-.91-2.786.112-3.826z"
    />
    <path
      fill="#f9dd8f"
      fillRule="evenodd"
      d="M44.001 41.999c0 4.413-3.881 8-8.667 8-14 0-19.333-16-19.333-16h19.333c4.785 0 8.667 3.574 8.667 8z"
    />
    <path
      fill="#8d6c9f"
      d="M46.001 15.493c0-.827-.672-1.493-1.5-1.493s-1.5.667-1.5 1.493a1.499 1.499 0 1 0 3 0z"
    />
    <path
      fill="#f6d397"
      fillRule="evenodd"
      d="M7.263 46.173a4.99 4.99 0 0 1 6.504.64C16.162 49.32 19.879 51 25 51h14c6.565 0 11.137-4.547 11-10.893a4.994 4.994 0 0 1 3.711-4.933c.74 1.52 1.253 3.173 1.289 4.827.2 9.253-6.759 16-16 16H25c-8.289-.002-14.578-3.655-17.737-9.828z"
    />
    <path
      fill="#faefde"
      fillRule="evenodd"
      d="M16.05 32.466c-3.313-1.173-6.045-4.2-7.449-5.907a40.808 40.808 0 0 1-1.156-1.467c1.268-3.067 2.556-5.093 2.556-5.093s4.952 8 9 8h12c2.7 0 2.871-1.733 2.091-3.053-1.903-3.2-2.431-6.053-2.431-7.467 0-6.773 5.86-12.173 12.781-11.413 3.984.453 7.44 3.027 9.1 6.573a4.985 4.985 0 0 1-6.269-.133c-.972-.813-2.149-1.333-3.383-1.467-3.921-.427-7.229 2.613-7.229 6.44 0 .16.041.76.308 1.68.257.907.699 2.013 1.421 3.24.804 1.347 1.643 3.747.665 6.28-1.024 2.64-3.516 4.093-6.291 4.293-.256.013-.511.027-.765.027H19.001a8.713 8.713 0 0 1-2.951-.533z"
    />
    <path
      fill="#8d6c9f"
      fillRule="evenodd"
      d="M4.001 35.999c0-8.427 5.155-16.533 5.155-16.533a1 1 0 0 1 1.695.013c.245.387 4.875 7.52 8.149 7.52h12.001c1.724 0 1.688-.773 1.231-1.547-1.987-3.333-2.571-6.373-2.571-7.973 0-7.36 6.369-13.24 13.891-12.4 5.481.613 10.035 4.933 10.892 10.387.567 3.6 1.004 5.84-2.329 9.28l-1.608 1.493c-.657.667-.688 1.76-.075 2.453 2.121 2.4 5.471 6.72 5.568 11.28.213 9.853-7.227 17.027-16.999 17.027h-14c-12.7 0-21-8.28-21-21zm50 4.027c-.081-3.76-2.929-7.587-5.067-10-1.32-1.493-1.239-3.787.148-5.187l.063-.067c4.411-3.773 3.9-5.32 3.324-9-.719-4.56-4.547-8.2-9.137-8.707-6.321-.693-11.671 4.24-11.671 10.413 0 1.213.471 3.893 2.289 6.96 1.103 1.853.636 4.56-2.951 4.56H19.001c-2.541 0-5.073-2.4-6.772-4.347a29.958 29.958 0 0 1-1.239-1.507l-.921-1.253-.757 1.48c-1.419 2.933-3.311 7.76-3.311 12.627 0 11.613 7.403 19 19 19h14c8.709.001 15.186-6.319 15-14.972z"
    />
    <path
      fill="#8d6c9f"
      fillRule="evenodd"
      d="M23.999 33.999c0-.547.448-1 1-1H30c.551 0 1 .453 1 1s-.449 1-1 1h-5.001c-.552 0-1-.453-1-1z"
    />
    <path
      fill="#8d6c9f"
      fillRule="evenodd"
      d="M15.051 34.319a1.002 1.002 0 0 1 .949-1.32h5c.551 0 1 .453 1 1s-.449 1-1 1h-3.533l.028.067c1.635 3.76 7.043 13.933 17.839 13.933 4.309 0 7.667-3.213 7.667-7 0-3.8-3.357-7-7.667-7h-1.335c-.552 0-1-.453-1-1s.448-1 1-1h1.335c5.26 0 9.667 3.96 9.667 9s-4.407 9-9.667 9c-14.715 0-20.255-16.6-20.283-16.68z"
    />
    <path
      fill="#ff7f7f"
      fillRule="evenodd"
      d="M51.626 23.999c6.432 0 7.375-7.573 7.375-7.573s-3.335.707-5.671-1.427c0 0-3.915 3.547-4.287 4.88-.378 1.36 1.9 4.12 2.583 4.12z"
    />
    <path
      fill="#8d6c9f"
      fillRule="evenodd"
      d="M50.971 24.839c-.68-.333-1.481-1.24-2.029-2.067-.515-.787-1.169-2.053-.863-3.16.156-.56.616-1.2 1.011-1.68.448-.56.996-1.16 1.519-1.693 1.047-1.08 2.044-1.973 2.049-1.987a1.003 1.003 0 0 1 1.347.013c.945.867 2.119 1.173 3.115 1.253.993.08 1.723-.08 1.672-.08a1.01 1.01 0 0 1 1.201 1.107c-.005.04-.261 2.08-1.392 4.147-1.151 2.093-3.284 4.307-6.975 4.307-.279 0-.511-.093-.655-.16zm5.878-5.106c.355-.653.608-1.307.784-1.853l.099-.347h-.315c-.145 0-.299-.013-.455-.027-.941-.067-2.112-.32-3.225-.973l-.347-.227-.287.28c-.315.307-.685.667-1.061 1.053-.503.52-1.003 1.053-1.393 1.547-.435.533-.615.867-.643.96-.007.027-.025.187.087.507.105.307.288.667.523 1.013.232.36.496.693.741.947.132.147.243.24.327.307l.093.08.348-.013c2.426-.188 3.848-1.668 4.724-3.254zM19.506 52.319a.99.99 0 0 1-.691-1.227l.544-1.933a.994.994 0 0 1 1.233-.68.99.99 0 0 1 .691 1.227l-.544 1.933a.994.994 0 0 1-1.233.68zM14.327 49.933a.999.999 0 0 1-.2-1.4l1.2-1.6a1 1 0 1 1 1.6 1.2l-1.2 1.6a1 1 0 0 1-1.4.2zM10.438 45.706a1.001 1.001 0 0 1 .349-1.373l1.72-1.013a.995.995 0 0 1 1.369.347c.283.48.125 1.093-.349 1.373l-1.72 1.013a.995.995 0 0 1-1.369-.347zM8.421 40.399a.99.99 0 0 1 .801-1.16l1.965-.373a1.01 1.01 0 0 1 1.165.813c.1.533-.259 1.053-.801 1.16l-1.965.36a.99.99 0 0 1-1.165-.8zM10.97 36.119l-1.997-.12a.997.997 0 0 1-.94-1.053 1.012 1.012 0 0 1 1.056-.947l1.997.12c.552.04.972.507.94 1.053a1.002 1.002 0 0 1-1.056.947zM11.619 31.439l-1.893-.64a.997.997 0 0 1-.625-1.267c.177-.52.747-.8 1.269-.627l1.893.64a.997.997 0 0 1 .625 1.267.985.985 0 0 1-1.269.627z"
    />
    <path
      fill="#fff8ee"
      fillRule="evenodd"
      d="M34.501 35.999a1.5 1.5 0 1 1 0 3A1.497 1.497 0 0 1 33 37.506a1.5 1.5 0 0 1 1.501-1.507z"
    />
    <path
      fill="#fff8ee"
      fillRule="evenodd"
      d="M34.506 38.999c2.5 0 4.56.08 6.495 1 0-1.467-4.008-4-6.495-4v3z"
    />
  </svg>
)
export default CanardEnCaoutchouc
